import CommonLayout from "components/layout/Layout";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Button, Select, Row, Col, Spin, Upload, message, Typography } from "antd";
import { InboxOutlined, FileTextOutlined, CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import AutomationService from "services/automation-templete/automation.service";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import "./../automation-template.css";

const { Dragger } = Upload;
const { Option } = Select;

const CreateTemplate = () => {
    const [loadingState, setLoadingState] = useState(false);
    const [reloadingState, setReLoadingState] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [templateNameError, setTemplateNameError] = useState("");
    const [promoTypeError, setPromoTypeError] = useState("");
    const [templateError, setTemplateError] = useState("");
    const [promoType, setPromoType] = useState("");
    const [promoTypes, setPromoTypes] = useState([]);
    const [promoQuestions, setPromoQuestions] = useState([]);
    const automationService = useMemo(() => new AutomationService(), []);
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [fileDetails, setFileDetails] = useState(null);
    const [excelHeaders, setExcelHeaders] = useState([]);
    const [fileSizeKB, setFileSizeKB] = useState(null);
    const [actionType, setActionType] = useState('create');
    const [templateId, setTemplateId] = useState(null);
    const [fileName, setFileName] = useState("");
    const [inputValue, setInputValue] = useState("");
    const params = useParams();
    
    const handleCancel = () => {
        navigate(`/csv-templates`);
    };

    const getPromoType = async () => {
        const promoTypeList = await automationService.getPromoType();
        setPromoTypes(promoTypeList?.data?.payload?.promoTypes);
    }
    const getPromoQuestion = async (id) => {
        const questionList = await automationService.getPromoQuestions(id);
        setPromoQuestions(questionList?.data);
    };
    const getTemplateData = async (id) => {
        const templateData = await automationService.getTemplateById(id);
        if (templateData) {
            setTemplateName(templateData?.data?.template?.name);
            setPromoType(templateData?.data?.template?.promoTypeId);
            setFileName(templateData?.data?.template?.fileName);
            setFileSizeKB(templateData?.data?.template?.fileSize ? templateData?.data?.template?.fileSize : null);
            setExcelHeaders(templateData?.data?.template?.mapData);
            getPromoQuestion(templateData?.data?.template?.promoTypeId);
        }
    }

    useEffect(() => {
        getPromoType();
    }, []);

    useEffect(() => {
        setTemplateId(params?.id);
        setActionType(params?.type ? params?.type : 'create')
        if (params?.id && params?.type) {
            getTemplateData(params?.id);
        }
    }, [params]);

    const handleSave = async () => {
        let isValid = true;

        if (!templateName) {
            setTemplateNameError("Template Name is required");
            isValid = false;
        } else {
            setTemplateNameError("");
        }

        if (!promoType) {
            setPromoTypeError("Promo Type is required");
            isValid = false;
        } else {
            setPromoTypeError("");
        }

        if (!fileName) {
            setTemplateError("Upload Template is required");
            isValid = false;
        } else {
            setTemplateError("");
        }

        if (isValid) {
            const data = {
                'name': templateName,
                'promoTypeId': promoType,
                'fileName': fileName,
                'fileSize': fileSizeKB,
                'activate': false,
                'mapData': excelHeaders
            };
            let response;
            if (actionType === 'edit') {
                response = await automationService.updateCSVTemplate(templateId, data);
            } else {
                response = await automationService.createCSVTemplate(data);
            }
            if (response?.status) {
                showAlert({
                    message: response?.message,
                    variant: AlertVariant.SUCCESS
                });
                navigate(`/csv-templates`);
            } else {
                showAlert({
                    message: response.message,
                    variant: AlertVariant.ERROR
                });
            }
        }
    };

    const handleTemplateName = (e) => {
        setTemplateName(e.target.value);
        setTemplateNameError("");
    };



    const handlePromoTypeChange = (value) => {
        setPromoTypeError("");
        setPromoType(value);
        getPromoQuestion(value);
    };

    const handleFileChange = async (info) => {
        setTemplateError("");
        setExcelHeaders([]);
        setFileDetails(null);
        const { file } = info;
        const isCSV = file.type === 'text/csv';
        const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (!isCSV && !isXLSX) {
            message.error('You can only upload CSV or XLSX files!');
            return;
        }

        const selectedFile = info?.fileList[0]?.originFileObj;
        const fileSizeInBytes = selectedFile.size;
        const fileSizeInKB = fileSizeInBytes / 1024;
        setFileSizeKB(fileSizeInKB.toFixed(2));

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            if (Array.isArray(jsonData) && jsonData.length > 0) {
                const headers = jsonData[0].map((header, index) => ({
                    title: header,
                    value: null,
                    key: index
                }));
                setExcelHeaders(headers);
                setFileDetails(selectedFile);
                setFileName(selectedFile.name);
            } else {
                message.error('Failed to parse the file data.');
            }
        };

        reader.onerror = () => {
            message.error('Failed to read the file.');
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const handleHeaderValueChange = (value, key) => {
        if (value) {
            const formattedValue = `{{${value}}}`;
            setExcelHeaders(prevHeaders =>
                prevHeaders.map(header =>
                    header.key === key ? { ...header, value: formattedValue } : header
                )
            );
        } else {
            setExcelHeaders(prevHeaders =>
                prevHeaders.map(header =>
                    header.key === key ? { ...header, value: null } : header
                )
            );
        }

    };

    const displayHeaderValue = (value) => {
        if (value) {
            if (value.startsWith("{{") && value.endsWith("}}")) {
                return value.slice(2, -2);
            }
        }
        return value;
    };

    const handleDeleteFile = () => {
        setFileDetails(null);
        setExcelHeaders([]);
        setFileSizeKB(null);
        setFileName("");
    };
    const handleSearch = (value) => {
        setInputValue(value);
    };
    return (
        <CommonLayout className="content-mr" breadCrumbText="Automation Template / CSV Template">
            {loadingState || reloadingState ? (
                <div className="errorTipDiv">
                    <Spin size="middle" tip="Loading..." />
                </div>
            ) : (
                <>
                    <div className="template_body">
                        <div className="template_header">
                            {actionType === 'view' ? 'Template Details' : actionType === 'edit' ? 'Update CSV Template' : 'Create CSV Template'}
                        </div>
                        <Row gutter={16} className="mb-25" style={{ width: '100%' }}>
                            <Col span={12}>
                                <div>
                                    <label>
                                        {actionType !== 'view' && <span className="spanReq">*</span>} Template Name
                                    </label>
                                    <Input
                                        placeholder="Write here"
                                        value={templateName}
                                        className="mt-5 full-width"
                                        onChange={handleTemplateName}
                                        disabled={actionType === 'view'}
                                    />
                                    <span className="errorLabel">{templateNameError}</span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <label>
                                        {actionType === 'create' && <span className="spanReq">*</span>} Promo Type
                                    </label>
                                    <Select
                                        allowClear
                                        style={{
                                            width: '100%',
                                            padding: '5px 0 0 0'
                                        }}
                                        placeholder="Search and select Promo Type"
                                        onChange={handlePromoTypeChange}
                                        value={promoType}
                                        showSearch
                                        disabled={actionType !== 'create'}
                                        filterOption={(input, option) => {
                                            return (option?.children?.toLowerCase() ?? "").includes(
                                                input?.toLowerCase()
                                            );
                                        }}
                                    >
                                        {promoTypes.map((option) => (
                                            <Option key={option._id} value={option._id} data={option}>
                                                {option.promoType}
                                            </Option>
                                        ))}
                                    </Select>
                                    <span className="errorLabel">{promoTypeError}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={16} className="mb-25" style={{ width: '100%' }}>
                            <div style={{ padding: '0 0 0 5px', width: '100%' }}>
                                <label>
                                    {actionType !== 'view' && <span className="spanReq">*</span>} Upload Template
                                </label>
                                <div className="upload-template">
                                    {fileName ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '30px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <FileTextOutlined className="fileIcon" />
                                                <span style={{ marginLeft: '10px' }}>
                                                    <span>{fileName}</span>
                                                    <br />
                                                    <span>{fileSizeKB ? fileSizeKB + 'KB' : ''} </span>
                                                </span>
                                            </div>
                                            {actionType == 'create' && <Button
                                                icon={<CloseOutlined />}
                                                className="deleteBtn"
                                                onClick={handleDeleteFile}
                                            />}
                                        </div>
                                    ) : (
                                        <Dragger
                                            onChange={handleFileChange}
                                            beforeUpload={() => false}
                                            multiple={false}
                                            showUploadList={false}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click to Upload Template</p>
                                        </Dragger>
                                    )}
                                </div>
                                <span className="errorLabel">{templateError}</span>
                            </div>
                        </Row>
                        {excelHeaders.length > 0 && (
                            <Row gutter={16} className="mb-25" style={{ width: '100%' }}>
                                <Col span={24} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                    <Typography className="header_template right-border">Template Fields</Typography>
                                    <Typography className="header_template">Promo Type Fields</Typography>
                                </Col>
                                {excelHeaders.map(header => (
                                    <Col span={24} key={header.key} style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
                                        <Input
                                            value={header.title}
                                            readOnly
                                            style={{ width: '50%', marginRight: '8px', backgroundColor: 'rgb(248 252 255)', borderColor: 'rgb(248 252 255)' }}
                                        />
                                        <ArrowRightOutlined className="arrow-icon" />
                                        <Select
                                            allowClear
                                            style={{ width: '50%' }}
                                            placeholder="Select value"
                                            onChange={(value) => handleHeaderValueChange(value, header.key)}
                                            onSearch={handleSearch}
                                            value={displayHeaderValue(header.value)}
                                            showSearch
                                            disabled={actionType === 'view'}
                                            notFoundContent={null}
                                        >
                                            {promoQuestions && promoQuestions.map((option) => (
                                                <Option key={option} value={option}>
                                                    {option}
                                                </Option>
                                            ))}
                                            {inputValue && !promoQuestions.includes(inputValue) && (
                                                <Option key={inputValue} value={inputValue}>
                                                    {inputValue}
                                                </Option>
                                            )}
                                        </Select>
                                    </Col>
                                ))}
                            </Row>
                        )}
                        <div className="button_div">
                            <Button onClick={handleCancel}>
                                Cancel
                            </Button>
                            {actionType !== 'view' && <Button type="primary" onClick={handleSave} className="next-btn">
                                Save
                            </Button>}
                        </div>
                    </div>
                </>
            )}
        </CommonLayout>
    );
}

export default CreateTemplate;
