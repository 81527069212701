import "./CreateAdset.css";
import "../promotion/elements/elements.css";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Space,
  Button,
  Input,
  DatePicker,
  Alert,
  Select,
  Modal,
  theme
} from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { DATE_FILTER_FORMAT, SECONDS } from "utils/constants";
import moment from "moment";
import { createEvent, updateEvent, deleteEventById, getAllAdsets } from "actions/calendar";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Validator } from 'utils/validations';
import AdSetService from "services/adset/adset.service";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const CreateEvent = (props) => {
  const dispatch = useDispatch();
  const [promoName, setPromoName] = useState("");
  const [eventId, setEventId] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [workflowDate, setworkflowDate] = useState("");
  const [lockInDate, setlockInDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [eventIdError, setEventIdError] = useState("");
  const [dateError, setDateError] = useState("");
  const [selectedAdset, setSelectedAdset] = useState();
  const [allowedStartDate, setAllowedStartDate] = useState('');
  const [allowedEndDate, setAllowedEndDate] = useState('');
  const [range, setRange] = useState('end');

  const [requiredErrorText] = useState("");
  const [requiredError] = useState(false);
  const { adsets } = useSelector((state) => state.calendar);
  const [deleteEeventErrorText, setDeleteEeventErrorText] = useState("");
  const [deleteEeventError, setDeleteEeventError] = useState(false);
  const [themes, setThemes] = useState([]);
  const [promoIds, setPromoIds] = useState([]);
  const [eventThemevalues, setEventThemeValues] = useState({
    eventTheme: '',
    promoId: ''
  });
  const [error, setError] = useState({
    eventTheme: '',
    promoId: ''
  });


  const adsetService = new AdSetService();

  const getThemes = async () => {
    const res = await adsetService.getThemesByType('THEME');
    if (res?.data) {
      setThemes(res.data?.map(item => ({ label: item.value, value: item.value })));
    }
  }

  const getPromoIds = async () => {
    const res = await adsetService.getPromoIds();
    if (res?.data) {
      setPromoIds(res.data?.map(item => ({ label: item.promo_id, value: item._id })));
    }
  }

  useEffect(() => {
    getThemes();
    getPromoIds();
  }, []);


  useEffect(() => {
    const event = props.eventData;
    if (event) {
      setPromoName(event.name);
      setDate([moment(event.startDate).format('MM/DD/YYYY'), moment(event.endDate).format('MM/DD/YYYY')]);
      const selectedDate = event.startDate ? moment(event.startDate) : null;
      const newDate = selectedDate ? selectedDate.clone().subtract(event.emergency_days, 'days') : null;
      newDate ? setworkflowDate(moment(newDate).format('MM/DD/YYYY')) : setworkflowDate(null);
      const lockIn = selectedDate ? selectedDate.clone().subtract(event.lock_period, 'days') : null;
      lockIn ? setlockInDate(moment(lockIn).format('MM/DD/YYYY')) : setlockInDate(null);
      setDescription(event?.description);
      formik.setValues({
        workflowDays: event?.emergency_days,
        lockinDays: event?.lock_period,
      });
      setSelectedAdset(typeof event?.adset === 'string' ? event?.adset : event?.adset?._id);
      const adset = adsets.filter((item) => item._id === event?.adset?._id);
      setAllowedStartDate(convertUTCToLocal(adset[0]?.startDate))
      setAllowedEndDate(convertUTCToLocal(adset[0]?.endDate))
      setEventId(event?.eventId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eventData]);

  const handleName = (e) => {
    if (e.target.value.length === 0 || e.target.value.trim()) {
      setPromoName(e.target.value);
    }
    !e.target.value ? setNameError("This field is required") : setNameError("");
    setNameError(Validator.validate('alphaNumeric_specialNotStart', e.target.value, null, null, true))
  };

  const handleEventId = (e) => {
    setEventId(e.target.value.trim());
    !e.target.value ? setEventIdError("This field is required") : setEventIdError("");
    setEventIdError(Validator.validate('alphanumericspace', e.target.value, null, null, true))
  };

  const handleDate = (value, dateString) => {
    setDate(dateString);
    const selectedDate = dateString[0] ? moment(dateString[0]) : null;
    const newDate = selectedDate ? selectedDate.clone().subtract(formik.values.workflowDays, 'days') : null;
    newDate ? setworkflowDate(moment(newDate).format('MM/DD/YYYY')) : setworkflowDate(null);
    const lockIn = selectedDate ? selectedDate.clone().subtract(formik.values.lockinDays, 'days') : null;
    lockIn ? setlockInDate(moment(lockIn).format('MM/DD/YYYY')) : setlockInDate(null);
    !dateString ? setDateError("This field is required") : setDateError("");
  };
  const [initialMonthDate, setInitialMonthDate] = useState(null);
  const convertDateFormat = (dateStr) => {
    if (dateStr) {
      const date = moment(dateStr, 'MM/DD/YYYY').format('YYYY-MM-DD');
      return date;
    } else {
      return null;
    }
  }
  const convertUTCToLocal = (utcDateStr) => {
    if (utcDateStr) {
      const date = moment.utc(utcDateStr).local().format('MM/DD/YYYY');
      return date;
    } else {
      return null;
    }
  }
  const handleSubmit = async (e) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (e === 'Submit') {
      let api = {
        name: promoName.trim(),
        startDate: convertDateFormat(date[0]),
        endDate: convertDateFormat(date[1]),
        description,
        emergency_days: formik.values.workflowDays,
        lock_period: formik.values.lockinDays,
        adset: selectedAdset,
        eventId,
        theme: eventThemevalues.eventTheme,
        promoId: eventThemevalues.promoId,
      };
      setLoading(true);
      const res = await dispatch(createEvent(api, timezone));
      setLoading(false);
      if (res?.error) {
        props.onClose('Error', res.error?.response?.data);
      } else {
        props.onClose('Create', res.payload.data.data);
      }
      await dispatch(getAllAdsets());
    }

    if (e === 'Update') {
      let api = {
        name: promoName.trim(),
        startDate: convertDateFormat(date[0]),
        endDate: convertDateFormat(date[1]),
        description,
        emergency_days: formik.values.workflowDays,
        lock_period: formik.values.lockinDays,
        adset: selectedAdset,
        eventId
      };
      setLoading(true);
      const res = await dispatch(updateEvent(api, props.eventData?._id, timezone));
      setLoading(false);
      if (res?.error) {
        props.onClose('Error', res?.error?.response?.data);
      } else {
        props.onClose('Update', res.payload.data.data);
      }
      await dispatch(getAllAdsets());
    }
    if (e === 'Delete') {
      setLoading(true);
      const res = await dispatch(deleteEventById(props.eventData?._id));
      if (res?.type === "DELETE_EVENT_FAIL") {
        setDeleteEeventErrorText(res?.error?.response?.data?.message);
        setDeleteEeventError(true)
        setLoading(false);
      } else {
        setLoading(false);
        props.onClose('Delete');
      }
    }
    await dispatch(getAllAdsets());
  };
  const handleInputChange = (e) => {
    const selectedDate = date[0] ? moment(date[0]) : null;
    const newDate = selectedDate ? selectedDate.clone().subtract(e.target.value, 'days') : null;
    newDate ? setworkflowDate(moment(newDate).format('MM/DD/YYYY')) : setworkflowDate(null);
  }
  const handleLockInInputChange = (e) => {
    const selectedDate = date[0] ? moment(date[0]) : null;
    const newDate = selectedDate ? selectedDate.clone().subtract(e.target.value, 'days') : null;
    newDate ? setlockInDate(moment(newDate).format('MM/DD/YYYY')) : setlockInDate(null);
  }
  const handleAdsetChange = (value, option) => {
    setDate('');
    setSelectedAdset(value);
    const startDate = convertUTCToLocal(option?.data?.startDate)
    setAllowedStartDate(startDate)
    const endDate = convertUTCToLocal(option?.data?.endDate)
    setAllowedEndDate(endDate)
    setInitialMonthDate(moment(option?.data?.startDate))
  };
  const disabledDate = (current) => {
    //// Commented due to #379
    // if (range === 'end') {
    //   return current && allowedStartDate && (current < moment(allowedStartDate) || current > moment(allowedEndDate));
    // } else {
    return current && allowedStartDate && (current < moment(allowedStartDate));
    // }
  };

  const deleteEvent = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this event?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => handleSubmit("Delete"),
      okType: 'primary',
    });
  }
  const validationSchema = Yup.object({
    workflowDays: Yup.number()
      .required('Required')
      .positive('Must be a positive number')
      .integer('Must be an integer'),
    lockinDays: Yup.number()
      .required('Required')
      .positive('Must be a positive number')
      .integer('Must be an integer')
      .max(30, 'Must be less than or equal to 30')
      .test('is-greater', 'Must be less than Workflow days', function (value) {
        const { workflowDays } = this.parent;
        return value < workflowDays;
      })
      .min(0, 'Must be greater than or equal to 0'),
  });

  const formik = useFormik({
    initialValues: {
      workflowDays: undefined,
      lockinDays: undefined,
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
    },
    context: { allowZero: true },
  });

  const handleAlertClose = () => {
    setDeleteEeventError(false);
  }

  useEffect(() => {
    if (deleteEeventError) {
      setTimeout(() => {
        setDeleteEeventError(false);
      }, SECONDS);
    }
  }, [deleteEeventError])

  return (
    <div>
      <Drawer
        title={`${props.eventData ? "Update" : "Create"} an event`}
        width={640}
        height={1900}
        onClose={props.onClose}
        open={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={
          <div>
            {props.eventData && <Button
              onClick={deleteEvent}
              danger disabled={props.editable}
            >
              {" "}
              {"Delete"}
            </Button>}
            <Space className="create-style">
              <Button
                onClick={() => props.onClose()}
                disabled={loading}
              >
                {" "}
                {loading ? `Loading...` : "Cancel"}
              </Button>
              <Button
                // type="submit"
                type="primary"
                onClick={() => handleSubmit(props.eventData ? "Update" : "Submit")}
                disabled={loading || !(promoName && eventId && date[0] && date[1] && formik.values.workflowDays && promoName && eventThemevalues.eventTheme && eventThemevalues.promoId) || props.editable}
              >
                {loading ? `Loading...` : `${props.eventData ? "Update" : "Submit"}`}
              </Button>
            </Space>
          </div>
        }
      >
        {requiredError && (
          <Alert
            message={requiredErrorText}
            type={"error"}
            className="create-frame"
            style={{ marginTop: '24px' }}
          />
        )}
        {deleteEeventError && (
          // <Alert
          //   message={deleteEeventErrorText}
          //   type={"error"}
          //   className="create-frame"
          //   style={{marginTop:'24px'}}
          // />
          <Alert message={deleteEeventErrorText} type="error"
            showIcon closable onClose={handleAlertClose} icon={<DeleteOutlined />} className='alertStyle'
          />
        )}
        <form onSubmit={formik.handleSubmit}>
          <div className="create-body">
            {/* <Error /> */}
            <div className="create-frame">
              <div className="create-promo-frame">
                <div >
                  <div className="d-label">
                    <span className="spanReq">*</span>{" "}
                    <label className="input-label">What is the name of the Event?</label>
                  </div>
                  <Input
                    className="input-text-field"
                    onChange={handleName}
                    placeholder="Write name"
                    value={promoName} disabled={props.editable}
                  />
                  <span className="error-label">{nameError}</span>

                  <TextArea name="" id="" disabled={props.editable} placeholder='Additional description' value={description} onChange={(e) => { setDescription(e.target.value) }} ></TextArea>
                </div>

                <div style={{ marginTop: '24px' }} >
                  <div className="d-label">
                    <span className="spanReq">*</span>{" "}
                    <label className="input-label">Event ID?</label>
                  </div>
                  <Input
                    className="input-text-field"
                    onChange={handleEventId}
                    placeholder="Write alphanumeric event id"
                    value={eventId} disabled={props.editable}
                  />
                  <span className="error-label">{eventIdError}</span>

                </div>
                <div style={{ marginTop: '24px' }} className="event-wrapper">
                  <div className="input-item">
                    <div className="d-label">
                      <span className="spanReq">*</span>{" "}
                      <label className="input-label">Event Theme</label>
                    </div>

                    <Select
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select Theme"
                      onChange={(e) => setEventThemeValues({ ...eventThemevalues, eventTheme: e })}
                      value={eventThemevalues.eventTheme} showSearch disabled={props.editable}
                      filterOption={(input, option) => {
                        return (option?.children?.toLowerCase() ?? "").includes(
                          input?.toLowerCase()
                        );
                      }}
                    >
                      {themes.map((option) => (
                        <Option key={option.value} value={option.value} data={option}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>


                    <span className="error-label">{error.eventTheme}</span>
                  </div>

                  <div className="input-item">
                    <div className="d-label">
                      <span className="spanReq">*</span>{" "}
                      <label className="input-label">Promo ID?</label>
                    </div>
                    <Select
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select Promo ID"
                      onChange={(e) => setEventThemeValues({ ...eventThemevalues, promoId: e })}
                      value={eventThemevalues.promoId} showSearch disabled={props.editable}
                      filterOption={(input, option) => {
                        return (option?.children?.toLowerCase() ?? "").includes(
                          input?.toLowerCase()
                        );
                      }}
                    >
                      {promoIds.map((option) => (
                        <Option key={option.value} value={option.value} data={option}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>

                    <span className="error-label">{error.promoId}</span>
                  </div>

                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className="d-label">
                    <span className="spanReq">*</span>{" "}
                    <label className="input-label">To which adset this event applies?</label>
                  </div>
                  <Space
                    style={{
                      width: '100%',
                    }}
                    direction="vertical"
                  >
                    <Select
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      placeholder="Search and select adset"
                      onChange={handleAdsetChange}
                      value={selectedAdset} showSearch disabled={props.editable}
                      filterOption={(input, option) => {
                        return (option?.children?.toLowerCase() ?? "").includes(
                          input?.toLowerCase()
                        );
                      }}
                    >
                      {adsets.map((option) => (
                        <Option key={option.value} value={option.value} data={option}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Space>
                </div>

                <div className="date-frame" style={{ marginTop: '24px' }}>
                  <div className="d-label">
                    <label className="input-label">
                      <span className="spanReq">*</span> When does the event
                      start and end?
                    </label>
                  </div>
                  <RangePicker
                    key={initialMonthDate ? initialMonthDate.format('YYYY-MM') : 'default'}
                    format={DATE_FILTER_FORMAT}
                    onChange={handleDate}
                    className="select-date"
                    allowClear={true}
                    value={[
                      date[0] && moment(date[0]),
                      date[1] && moment(date[1]),
                    ]}
                    disabledDate={disabledDate}
                    onCalendarChange={(val, mode, info) => {
                      setRange(info.range);
                    }}
                    disabled={!selectedAdset || props.editable}
                    placeholder={['Start Date (MM/DD/YYYY)', 'End Date (MM/DD/YYYY)']}
                    defaultPickerValue={initialMonthDate ? [initialMonthDate, initialMonthDate] : []}
                  />
                  <span className="error-label">{dateError}</span>
                </div>
                <div >
                  <div className="d-label">
                    <span className="spanReq">*</span>{" "}
                    <label className="input-label">Days before the emergency workflow applies?</label>
                  </div>
                  <Input
                    className="input-text-field"
                    placeholder="Write the days"
                    type="number"
                    name="workflowDays"
                    onChange={(event) => { formik.handleChange(event); handleInputChange(event); }}
                    onBlur={formik.handleBlur} disabled={props.editable}
                    value={formik.values.workflowDays}
                  />
                  {formik.touched.workflowDays && formik.errors.workflowDays ? (
                    <div className="error-label">{formik.errors.workflowDays}</div>
                  ) : null}
                  {formik.values.workflowDays && workflowDate ? (<span className="spanReq">Note:- Emergency workflow for associated promo will start after {workflowDate}.</span>) : ""}
                </div>
                <div style={{ marginTop: '24px' }}>
                  <div className="d-label">
                    <span className="spanReq">*</span>{" "}
                    <label className="input-label">Days before the lock in period?</label>
                  </div>
                  <Input
                    className="input-text-field"
                    placeholder="Write the days"
                    type="number"
                    name="lockinDays" disabled={props.editable}
                    onChange={(event) => { formik.handleChange(event); handleLockInInputChange(event); }}
                    onBlur={formik.handleBlur}
                    value={formik.values.lockinDays}
                  />
                  {formik.touched.lockinDays && formik.errors.lockinDays ? (
                    <div className="error-label">{formik.errors.lockinDays}</div>
                  ) : null}
                  {formik.values.lockinDays && lockInDate ? (<span className="spanReq">Note:- User won't be able to create promo on or after {lockInDate}.</span>) : ""}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default CreateEvent;
