import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { editPromo, updateStage } from "actions/promotions/index";
import { Drawer, Button, Radio, Spin } from "antd";
import "./createPromoNew.css";
import ViewActivity from "./ViewActivity";
import StatusDrawer from "components/common/statusDrawer";
import CreateStaticForm from "./CreateStaticForm";
import Teams from "./teams";
import RejectionModal from "./RejectionModal";
import session from "utils/session";
import { QuestionStatus } from "utils/constants";
import SaticQuestionForm from "./staticQuestionForm";
const EditPromo = (props) => {
  const [data, setData] = useState(props.data);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const dispatch = useDispatch();
  const [stages, setStages] = useState([]);
  const [currentStage, setCurrentStage] = useState(0);
  const [staticQuestions, setStaticQuestions] = useState([]);
  const [mode, setMode] = useState("promo");
  const [loadingState] = useState(false);
  const [showCreateAlert, setShowCreateAlert] = useState("");
  const [approveflow, setApproveFlow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef();
  const teamsRef = useRef();
  const staticQuestionsRef = useRef();
  const userId = session.userId;
  const isAdmin = session.role === "Admin";
  const [isApproved, setIsApproved] = useState(false);
  useEffect(() => {
    if (data?.stages) {
      setStages(data.stages);
    }
    if (data?.staticQuestions) {
      setStaticQuestions(data.staticQuestions);
    }
  }, [data]);

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  const myTeamIndex = useMemo(() => {
    return isAdmin ? 0 : stages.findIndex((i) => i.teamId === session.teamId);
  }, [stages, isAdmin]);
  const handleSubmit = async (e, approveAlso) => {
    if (e === QuestionStatus.Submitted) {
      const isFormValid = formRef.current.isValid();
      const isTeamValid = teamsRef.current.isValid();
      const isStaticQuestionValid = staticQuestionsRef.current.isValid();
      if (!isTeamValid || !isFormValid || !isStaticQuestionValid) {
        return;
      }
    }
    const payload = formRef.current.getFormData();
    payload.stages = teamsRef.current.getStagesData();
    payload.staticQuestions = staticQuestionsRef.current.getStaticQuestionData();
    if (e === QuestionStatus.Incomplete) {
      setLoadingSave(true);
    } else if (e === QuestionStatus.Submitted) {
      setLoading(true);
      payload.actionTeam = stages[myTeamIndex + 1]
        ? stages[myTeamIndex + 1]?.teamId
        : stages[myTeamIndex]?.teamId;
      payload.actionTeamName = stages[myTeamIndex + 1]
        ? stages[myTeamIndex + 1]?.name
        : stages[myTeamIndex]?.name;
    }
    payload.isDraft = e === QuestionStatus.Incomplete;
    const res = await dispatch(editPromo(data?._id, payload));
    if (res.error === undefined) {
      if (approveAlso) {
        await dispatch(
          updateStage(data._id, {
            teamId: stages[currentStage || 0].teamId,
            status: QuestionStatus.Approved,
          })
        );
      } else {
        await dispatch(
          updateStage(res.payload?.data?.data._id, {
            teamId: stages[myTeamIndex || 0]?.teamId,
            status: QuestionStatus.Submitted,
          })
        );
      }
      setShowCreateAlert("Promo data has been submitted successfully ");
    }
  };

  const handleApprove = async () => {
    const res = await dispatch(
      updateStage(data._id, {
        teamId: stages[currentStage].teamId,
        status: QuestionStatus.Approved,
      })
    );
    if (res.error === undefined) {
      setIsApproved(true);
      setApproveFlow(true);
      setShowCreateAlert("Promo data has been approved successfully ");
      if (res?.payload?.data?.data) {
        setData((prev) => ({
          ...res?.payload?.data?.data,
          owner: prev.owner,
          adset: prev.adset,
        }));
      }
    }
  };

  const onRejectClick = () => {
    setShowModal(true);
  };
  const updateStages = useCallback((stages) => {
    // setStages(stages);
  }, []);
  const updateStaticQuestion = useCallback((questions) => {
    // setStaticQuestions(questions);
  }, [])
  const renderSubmitFooter = (isLast, showDraftButton = false) => {
    return (
      <>
        {showDraftButton ? (
          <Button
            type="link"
            onClick={() => handleSubmit("Incomplete")}
            disabled={loading || loadingSave}
          >
            {loadingSave ? `Loading...` : "Save as draft"}
          </Button>
        ) : (
          <div />
        )}
        <div>
          {" "}
          <Button
            onClick={() => isApproved ? props.onClose({ refresh: true }) : props.onClose()}
            disabled={loading || loadingSave}
          >
            {loading ? `Loading...` : "Cancel"}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              handleSubmit("Submitted", isLast);
            }}
            disabled={loading || loadingSave}
            className="next-btn"
          >
            {loading ? `Loading...` : isLast ? "Submit & Approve" : "Submit"}
          </Button>
        </div>
      </>
    );
  };

  const renderRejectFooter = () => {
    return (
      <>
        <Button type="link" onClick={() => props.onClose()}>
          Cancel
        </Button>
        <div>
          <Button
            onClick={() => onRejectClick()}
            disabled={loading || loadingSave}
          >
            {loading ? `Loading...` : "Reject"}
          </Button>
        </div>
      </>
    );
  };

  const renderApproveFooter = () => {
    return (
      <>
        <Button type="link"
          onClick={() => isApproved ? props.onClose({ refresh: true }) : props.onClose()}
        >
          Cancel
        </Button>
        <div>
          <Button
            onClick={() => onRejectClick()}
            disabled={loading || loadingSave}
          >
            {loading ? `Loading...` : "Reject"}
          </Button>
          <Button
            type="primary"
            onClick={() => handleApprove()}
            className="next-btn"
          >
            {loading ? `Loading...` : "Approve"}
          </Button>
        </div>
      </>
    );
  };
  const renderFooter = () => {
    return (
      <>
        <Button type="link" onClick={() => props.onClose()}>
          Cancel
        </Button>
        <div></div>
      </>
    );
  };
  const footer = () => {
    const isDraft = data?.isDraft;
    if (!stages.length) {
      return null;
    }
    if (data?.promoStatus === QuestionStatus.Approved) {
      return (
        <div className="flex space-between w-full create-footer">
          {renderFooter()}
        </div>
      );
    }
    if (isDraft) {
      if (data?.createdBy === userId) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderSubmitFooter(false, true)}
          </div>
        );
      }
      return (
        <div className="flex space-between w-full create-footer">
          {renderFooter()}
        </div>
      );
    }

    if (
      [QuestionStatus.Submitted, QuestionStatus.Approved].includes(
        stages?.[myTeamIndex]?.status
      )
    ) {
      if (currentStage > myTeamIndex) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderFooter()}
          </div>
        );
      }
    }

    if (
      [QuestionStatus.Approved, QuestionStatus.Rejected].includes(
        stages[currentStage]?.status
      )
    ) {
      if (currentStage < myTeamIndex) {
        if (stages[currentStage]?.status === QuestionStatus.Approved) {
          return (
            <div className="flex space-between w-full create-footer">
              {renderRejectFooter()}
            </div>
          );
        }
        if (stages[currentStage]?.status === QuestionStatus.Rejected) {
          return (
            <div className="flex space-between w-full create-footer">
              {renderFooter()}
            </div>
          );
        }
      }
      if (
        currentStage === myTeamIndex &&
        stages[currentStage]?.status === QuestionStatus.Rejected
      ) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderSubmitFooter()}
          </div>
        );
      }
      return (
        <div className="flex space-between w-full create-footer">
          {renderFooter()}
        </div>
      );
    }

    if (currentStage === myTeamIndex - 1) {
      return (
        <div className="flex space-between w-full create-footer">
          {renderApproveFooter()}
        </div>
      );
    }
    if (
      stages[Math.max(myTeamIndex - 1, 0)]?.status === QuestionStatus.Submitted
    ) {
      return (
        <div className="flex space-between w-full create-footer">
          {renderFooter()}
        </div>
      );
    }
    if (currentStage === myTeamIndex) {
      if (stages[currentStage]?.status === QuestionStatus.Submitted) {
        return (
          <div className="flex space-between w-full create-footer">
            {renderFooter()}
          </div>
        );
      }
      return (
        <div className="flex space-between w-full create-footer">
          {renderSubmitFooter(currentStage === stages.length - 1)}
        </div>
      );
    }
    return (
      <div className="flex space-between w-full create-footer">
        {renderSubmitFooter()}
      </div>
    );
  };

  const getActionType = () => {
    const isDraft = data?.isDraft;
    if (isDraft) {
      return data?.createdBy === userId ? "edit" : "view";
    }

    if (
      currentStage === Math.max(0, myTeamIndex - 1) &&
      stages[currentStage]?.status === QuestionStatus.Submitted
    ) {
      return "edit";
    }
    if (
      (myTeamIndex === currentStage &&
        [QuestionStatus.Submitted, QuestionStatus.Approved].includes(
          stages[currentStage]?.status
        )) ||
      stages[myTeamIndex - 1]?.status === QuestionStatus.Submitted ||
      currentStage < myTeamIndex
    ) {
      return "view";
    }

    return "edit";
  };
  const getStaticQuestionActionType = () => {
    return data.actionTeam == data.stages[0].teamId ? "edit" : "view";
  }
  return (
    <div>
      <Drawer
        title={`Edit Promo`}
        width={640}
        height={1900}
        onClose={() => isApproved ? props.onClose({ refresh: true }) : props.onClose()}
        open={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={footer()}
      >
        <Radio.Group
          onChange={handleModeChange}
          value={mode}
          style={{
            marginBottom: 8,
            width: "100%",
            display: "flex",
          }}
        >
          <Radio.Button value="promo" style={{ width: "100%" }}>
            Promo
          </Radio.Button>
          <Radio.Button value="activity" style={{ width: "100%" }}>
            Activity log
          </Radio.Button>
        </Radio.Group>
        {!loadingState && mode === "promo" && (
          <>
            <CreateStaticForm
              ref={formRef}
              setStages={updateStages}
              setStaticQuestions={updateStaticQuestion}
              data={data}
              disabled={true}
              type="edit"
            />

            <div style={{ marginTop: '15px' }}>
              <SaticQuestionForm
                ref={staticQuestionsRef}
                questions={staticQuestions}
                action={getStaticQuestionActionType()}
              />
            </div>

            <Teams
              stages={stages}
              ref={teamsRef}
              isDraft={data.isDraft}
              action={getActionType()}
              teamClick={(index) => setCurrentStage(index)}
            />
          </>
        )}
        {loadingState && (
          <div className="errorTipDiv">
            <Spin size="middle" tip="Loading..." />
          </div>
        )}

        {!loadingState && mode === "activity" && (
          <ViewActivity id={props?.data?._id} />
        )}
      </Drawer>
      <RejectionModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        showAlert={(
          message = "The rejection comment have been successfully added!"
        ) => {
          setShowCreateAlert(message);
        }}
        myTeamIndex={myTeamIndex}
        data={data}
        currentStage={currentStage}
        stages={stages}
      />
      {showCreateAlert && (
        <StatusDrawer
          onClose={() => {
            setShowCreateAlert(false);
            if (approveflow) {
              teamsRef.current?.handleStageChange(currentStage + 1);
              setApproveFlow(false);
            } else {
              props.onClose({ refresh: true });
            }
          }}
          title={showCreateAlert}
          visible={showCreateAlert}
          subtitle="You can check at “All promo”"
        />
      )}
    </div>
  );
};

export default EditPromo;
