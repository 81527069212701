import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Select, Spin } from "antd";
import Element from "../Element";
import { getPromoList, sendSub1 } from "actions/promotions";
import { getAllShelf, getSKUBySearch } from "actions/shelf";
import { getAllUsers } from "actions/users";
import { getBrand, getClass, getDepartment, getSubclass, getZone } from "actions/sku";
const { Option } = Select;

const MasterDropDownEle = (props) => {
  const [value, setValue] = useState(props?.ans || props?.options?.value || null);
  const dispatch = useDispatch();
  const [obj, setObj] = useState(null);
  const [subOption, setSubOption] = useState(false);
  const [promoOptionData, setPromoOptionData] = useState([])
  const [shelfOptionData, setShelfOptionData] = useState([])
  const [userOptionData, setUserOptionData] = useState([])
  const [brandOptionData, setBrandOptionData] = useState([])
  const [zoneOptionData, setzoneOptionData] = useState([])
  const [classOptionData, setclassOptionData] = useState([])
  const [subclassOptionData, setsubclassOptionData] = useState([])
  const [departmentOptionData, setdepartmentOptionData] = useState([])
  const [fetching, setFetching] = useState(false);
  const [dataSku, setDataSku] = useState([]);
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    if (props?.options?.masterDataType === 'promo') {
      const getPromoCall = async () => {
        const res = await dispatch(getPromoList())

        if (res.error === undefined) {
          setPromoOptionData(res.payload.data.data.payload ? res.payload.data.data.payload.promoList : res.payload.data.data.promoList || [])
        }
      }
      getPromoCall()
    }
    if (props?.options?.masterDataType === 'user') {
      const getUserCall = async () => {
        const res = await dispatch(getAllUsers())

        if (res.error === undefined) {
          setUserOptionData(res.payload.data.data.users || [])
        }
      }
      getUserCall()
    }
    if (props?.options?.masterDataType === 'shelf') {
      const getShelfCall = async () => {
        const res = await dispatch(getAllShelf())

        if (res.error === undefined) {
          setShelfOptionData(res.payload.data.data.payload.shelfList || [])
        }
      }
      getShelfCall()
    }

    if (props?.options?.masterDataType === 'brand') {
      const getBrandCall = async () => {
        const res = await dispatch(getBrand())

        if (res.error === undefined) {
          setBrandOptionData(res.payload.data.data || [])
        }
      }
      getBrandCall()
    }

    if (props?.options?.masterDataType === "department") {
      const getDepartmentCall = async () => {
        const res = await dispatch(getDepartment());

        if (res.error === undefined) {
          setdepartmentOptionData(res?.payload?.data?.data?.skuList || []);
        }
      };
      getDepartmentCall();
    }

    if (props?.options?.masterDataType === "zone") {
      const getZoneCall = async () => {
        const res = await dispatch(getZone());

        if (res.error === undefined) {
          setzoneOptionData(res?.payload?.data?.data?.skuList || []);
        }
      };
      getZoneCall();
    }

    if (props?.options?.masterDataType === "class") {
      const getclassCall = async () => {
        const res = await dispatch(getClass());

        if (res.error === undefined) {
          setclassOptionData(res?.payload?.data?.data?.skuList || []);
        }
      };
      getclassCall();
    }

    if (props?.options?.masterDataType === "subclass") {
      const getsubclassCall = async () => {
        const res = await dispatch(getSubclass());

        if (res.error === undefined) {
          setsubclassOptionData(res?.payload?.data?.data?.skuList || []);
        }
      };
      getsubclassCall();
    }


  }, [props.options, dispatch])



  useEffect(() => {
    const value = props.ans || null;
    if (value && props.subOptions && (props.action === "view" || props.action === "edit")) {
      const object1 = props.options?.filter((item) =>
        (item?.optionText || item?.subOptionText)
          .toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim())
      );

      if (object1[0]?.subOptions?.length) {
        setSubOption(true);
        setObj(object1[0]?.subOptions);
      } else {
        setSubOption(false);
        dispatch(sendSub1(null, null, props.id));
        setObj(false);
      }
      return;
    }
  }, [props.subOptions, dispatch, props.action, props.ans, props.id, props.options]);

  const handleSubChange = (value, id) => {
    dispatch(sendSub1(value, id, props.id));
  };

  const onChange = (e) => {
    setValue(e);
    props.isPromoTypeQuestion ? props.handlePromoTypeQuestionChange(e, props.id, props.pqid) : props.onChange(e, props.id);
  };

  const handleSearchSkuKeys = async (value) => {
    clearTimeout(timer)

    if (value.length >= 3) {
      const newTimer = setTimeout(async () => {
        setFetching(true)
        setDataSku([])
        const res = await dispatch(getSKUBySearch(value))
        if (res.error === undefined) {
          setDataSku(res.payload.data.data)
          setFetching(false)
        } else {
          setFetching(false)
        }
      }, 700)
      setTimer(newTimer)
    }


  }

  const promoChildren = [];
  if (promoOptionData.length > 0) {
    const filteredPromo = promoOptionData.filter(item => item?.status?.toLowerCase() !== 'expired')
    for (let i = 0; i < filteredPromo.length; i++) {
      promoChildren.push(<Option key={filteredPromo[i].name}>{filteredPromo[i].name}</Option>);
    }
  }
  const userChildren = [];
  if (userOptionData.length > 0) {
    const filteredUser = userOptionData.filter(item => item.isActive === true)
    for (let i = 0; i < filteredUser.length; i++) {
      userChildren.push(<Option key={filteredUser[i].fullName || filteredUser[i].firstName}>{filteredUser[i].fullName || filteredUser[i].firstName}</Option>);
    }
  }

  const shelfChildren = [];
  if (shelfOptionData.length > 0) {
    for (let i = 0; i < shelfOptionData.length; i++) {
      shelfChildren.push(<Option key={shelfOptionData[i].shelfPath}>{shelfOptionData[i].shelfPath}</Option>);
    }
  }
  const skuChildren = [];
  if (dataSku.length > 0) {
    for (let i = 0; i < dataSku.length; i++) {
      skuChildren.push(<Option key={dataSku[i].sku_key}>{dataSku[i].sku_key}</Option>);
    }
  }
  const brandChildren = [];
  if (brandOptionData.length > 0) {
    for (let i = 0; i < brandOptionData.length; i++) {
      brandChildren.push(<Option key={brandOptionData[i].sku_brand_desc}>{brandOptionData[i].sku_brand_desc}</Option>);
    }
  }
  // ======
  const zoneChildren = [];
  if (zoneOptionData.length > 0) {
    for (let i = 0; i < zoneOptionData.length; i++) {
      zoneChildren.push(
        <Option key={zoneOptionData[i].zone_id}>
          {zoneOptionData[i].name}
        </Option>
      );
    }
  }
  const departmentChildren = [];
  if (departmentOptionData.length > 0) {
    for (let i = 0; i < departmentOptionData.length; i++) {
      departmentChildren.push(
        <Option key={departmentOptionData[i].department_id}>
          {departmentOptionData[i].department_desc}
        </Option>
      );
    }
  }
  const classChildren = [];
  if (classOptionData.length > 0) {
    for (let i = 0; i < classOptionData.length; i++) {
      classChildren.push(
        <Option key={classOptionData[i].class_id}>
          {classOptionData[i].class_desc}
        </Option>
      );
    }
  }
  const subclassChildren = [];
  if (subclassOptionData.length > 0) {
    for (let i = 0; i < subclassOptionData.length; i++) {
      subclassChildren.push(
        <Option key={subclassOptionData[i].sub_class_id}>
          {subclassOptionData[i].sub_class_desc}
        </Option>
      );
    }
  }

  return (
    <>
      <div className="input-frame">
        <label className="input-label">
          {props.designate && props.designate === "required" ? (
            <span className="spanReq">*</span>
          ) : (
            ""
          )}{" "}
          {props?.options?.subOptionText || props?.options?.questionHeader}
        </label>
        {props.action === "create" && (
          <div className="select-dropdown">
            {props.options.masterDataType === "sku" && (
              <Select
                className="select-dropdown"
                showSearch
                onChange={onChange}
                value={value || []}
                placeholder="Search and Select SKU"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchSkuKeys}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                mode={fetching ? "multiple" : "tags"}
                tokenSeparators={[","]}
                maxTagCount={3}
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "sku" &&
                  skuChildren &&
                  skuChildren.length > 0 &&
                  skuChildren}
              </Select>
            )}

            {props.options.masterDataType === "brand" && (
              <Select
                showSearch
                placeholder="Search and select Brand"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "brand" &&
                  brandChildren &&
                  brandChildren.length > 0 &&
                  brandChildren}
              </Select>
            )}
            {/* new one */}
            {props.options.masterDataType === "zone" && (
              <Select
                showSearch
                placeholder="Search and select zone"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value || []}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                mode='multiple'
                maxTagCount={3}
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "zone" &&
                  zoneChildren &&
                  zoneChildren.length > 0 &&
                  zoneChildren}
              </Select>

              // <Select
              //   showSearch
              //   placeholder="Search and select zone"
              //   optionFilterProp="children"
              //   className="select-dropdown"
              //   onChange={onChange}
              //   value={value}
              //   filterOption={(input, option) =>
              //     option.children.toLowerCase().includes(input.toLowerCase())
              //   }
              // >
              //   {props.options.masterDataType &&
              //     props.options.masterDataType === "zone" &&
              //     zoneChildren &&
              //     zoneChildren.length > 0 &&
              //     zoneChildren}
              // </Select>
            )}
            {props.options.masterDataType === "department" && (
              <Select
                showSearch
                placeholder="Search and select department"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "department" &&
                  departmentChildren &&
                  departmentChildren.length > 0 &&
                  departmentChildren}
              </Select>
            )}
            {props.options.masterDataType === "class" && (
              <Select
                showSearch
                placeholder="Search and select class"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "class" &&
                  classChildren &&
                  classChildren.length > 0 &&
                  classChildren}
              </Select>
            )}
            {props.options.masterDataType === "subclass" && (
              <Select
                showSearch
                placeholder="Search and select subclass"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "subclass" &&
                  subclassChildren &&
                  subclassChildren.length > 0 &&
                  subclassChildren}
              </Select>
            )}
            {props.options.masterDataType === "promo" && (
              <Select
                showSearch
                placeholder="Search and select promo"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "promo" &&
                  promoChildren &&
                  promoChildren.length > 0 &&
                  promoChildren}
              </Select>
            )}
            {/* new one end*/}

            {/* for create user */}
            {props.options.masterDataType === "user" && (
              <Select
                showSearch
                placeholder="Search and select User"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "user" &&
                  userChildren &&
                  userChildren.length > 0 &&
                  userChildren}
              </Select>
            )}
            {/* end user create */}

            {props.options.masterDataType &&
              props.options.masterDataType !== "sku" &&
              props.options.masterDataType !== "brand" &&
              props.options.masterDataType !== "user" &&
              props?.options?.masterDataType !== "zone" &&
              props?.options?.masterDataType !== "department" &&
              props?.options?.masterDataType !== "class" &&
              props?.options?.masterDataType !== "subclass" && (
                <Select
                  className="select-dropdown"
                  onChange={onChange}
                  value={value}
                  placeholder="Select"
                >
                  {props.options.masterDataType &&
                    props.options.masterDataType === "promo" &&
                    promoChildren &&
                    promoChildren.length > 0 &&
                    promoChildren}
                  {props.options.masterDataType &&
                    props.options.masterDataType === "shelf" &&
                    shelfChildren &&
                    shelfChildren.length > 0 &&
                    shelfChildren}
                </Select>
              )}
            <span className="error-label">{props.error}</span>
          </div>
        )}
        {props.action === "edit" && (
          <div className="select-dropdown">
            {props?.options?.masterDataType === "sku" && (
              <Select
                className="select-dropdown"
                showSearch
                onChange={onChange}
                value={value || []}
                placeholder="Search and Select"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchSkuKeys}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                mode={fetching ? "multiple" : "tags"}
                tokenSeparators={[","]}
                maxTagCount={3}
              >
                {props?.options?.masterDataType === "sku" &&
                  skuChildren &&
                  skuChildren.length > 0 &&
                  skuChildren}
              </Select>
            )}

            {props.options.masterDataType === "brand" && (
              <Select
                showSearch
                placeholder="Search and select Brand"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "brand" &&
                  brandChildren &&
                  brandChildren.length > 0 &&
                  brandChildren}
              </Select>
            )}

            {/* for edit user */}
            {props.options.masterDataType === "user" && (
              <Select
                showSearch
                placeholder="Search and select User"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "user" &&
                  userChildren &&
                  userChildren.length > 0 &&
                  userChildren}
              </Select>
            )}
            {/*end edit user  */}

            {props?.options?.masterDataType !== "sku" &&
              props?.options?.masterDataType !== "brand" &&
              props?.options?.masterDataType !== "user" &&
              props?.options?.masterDataType !== "zone" &&
              props?.options?.masterDataType !== "department" &&
              props?.options?.masterDataType !== "class" &&
              props?.options?.masterDataType !== "subclass" && (
                <Select
                  className="select-dropdown"
                  onChange={onChange}
                  value={value}
                  placeholder="Select"
                >
                  {props?.options?.masterDataType === "promo" &&
                    promoChildren &&
                    promoChildren.length > 0 &&
                    promoChildren}

                  {props?.options?.masterDataType === "shelf" &&
                    shelfChildren &&
                    shelfChildren.length > 0 &&
                    shelfChildren}
                </Select>
              )}
            <span className="error-label">{props.error}</span>
          </div>
        )}
        {props.action === "view" && (
          <div className="select-dropdown">
            {props.options.masterDataType === "sku" && (
              <Select
                className="select-dropdown"
                showSearch
                onChange={onChange}
                value={value || []}
                placeholder="Search and Select"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                disabled
                onSearch={handleSearchSkuKeys}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                mode={fetching ? "multiple" : "tags"}
                tokenSeparators={[","]}
                maxTagCount={3}
              >
                {props.options.masterDataType === "sku" &&
                  skuChildren.length > 0 &&
                  skuChildren}
              </Select>
            )}

            {props.options.masterDataType === "brand" && (
              <Select
                showSearch
                placeholder="Search and select Brand"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                disabled
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "brand" &&
                  brandChildren &&
                  brandChildren.length > 0 &&
                  brandChildren}
              </Select>
            )}

            {/* for view user */}
            {props.options.masterDataType === "user" && (
              <Select
                showSearch
                placeholder="Search and select User"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                disabled
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {props.options.masterDataType &&
                  props.options.masterDataType === "user" &&
                  userChildren &&
                  userChildren.length > 0 &&
                  userChildren}
              </Select>
            )}
            {/* end view user */}

            {props.options.masterDataType !== "sku" &&
              props.options.masterDataType !== "brand" &&
              props.options.masterDataType !== "user" &&
              props?.options?.masterDataType !== "zone" &&
              props?.options?.masterDataType !== "department" &&
              props?.options?.masterDataType !== "class" &&
              props?.options?.masterDataType !== "subclass" && (
                <Select
                  className="select-dropdown"
                  onChange={onChange}
                  value={value}
                  disabled
                  placeholder="Select"
                >
                  {props.options.masterDataType === "promo" &&
                    promoChildren.length > 0 &&
                    promoChildren}
                  {props.options.masterDataType === "shelf" &&
                    shelfChildren.length > 0 &&
                    shelfChildren}
                </Select>
              )}
            <span className="error-label">{props.error}</span>
          </div>
        )}
      </div>
      {subOption && props.action === "create" && (
        <Element
          field={obj[0]?.subOptionType}
          label={obj[0]?.subOptionText}
          options={obj[0]?.subOptions}
          onChange={handleSubChange}
          id={"subOptions"}
          action={props.action}
        />
      )}
      {subOption && props.action === "view" && (
        <Element
          ans={props?.subOptions[0]?.value}
          field={obj[0]?.subOptionType}
          label={obj[0]?.subOptionText}
          options={obj[0]?.subOptions}
          action={props.action}
          onChange={handleSubChange}
          id={"sub" + obj[0]?.subOptionType}
        />
      )}
      {subOption && props.action === "edit" && (
        <Element
          ans={props?.subOptions[0]?.value}
          field={obj[0]?.subOptionType}
          label={obj[0]?.subOptionText}
          options={obj[0]?.subOptions}
          action={props.action}
          onChange={handleSubChange}
          id={"sub" + obj[0]?.subOptionType}
        />
      )}
    </>
  );
};

export default MasterDropDownEle;
