import React from "react";
import RadioEle from "./elements/RadioEle";
import DropDownEle from "./elements/DropDownEle";
import DateEle from "./elements/DateEle";
import CheckEle from "./elements/CheckEle";
import InputEle from "./elements/InputEle";
import TextEle from "./elements/TextEle";
import TabsEle from "./elements/TabsEle";
import MultipleSelect from "./elements/multipleSelect";
import MasterDropDownEle from "./elements/MasterDropDownEle";
import PromoTypeElement from "./elements/PromoTypeElement";
import DropDownWithSearch from "./elements/DropDownWithSearch";
import MediaElement from "./elements/mediaElement";
const Element = (props) => {
  console.log(props, 'element')
  return (
    <>
      {props.field === "input" && (
        <InputEle
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          action={props.action}
          ans={props.ans}
          error={props.error}
          sub={props.sub}
          id={props.id}
          id1={props.id1}
          designate={props.designate}
          isRegexRequired={props.isRegexRequired}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
          details={props.details}
          Regex={props.regex}
        />
      )}
      {props.field === "media" && (
        <MediaElement
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          action={props.action}
          ans={props.ans}
          error={props.error}
          sub={props.sub}
          id={props.id}
          id1={props.id1}
          designate={props.designate}
          isRegexRequired={props.isRegexRequired}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
          details={props.details}
          
        />
      )}
      {props.field === "date" && (
        <DateEle
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
        />
      )}
      {props.field === "dropdown" && !props.item?.isMaster && (
        <DropDownEle
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          subOptions={props.subOptions}
          SubOptionInput={props.SubOptionInput}
          SubOptionInputThree={props.SubOptionInputThree}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
          details={props.details}
        />
      )}
      {props.field === "dropdown" &&
        props.item?.isMaster &&
        props.item?.isMaster === true &&
        props.item?.masterDataType === "promo" ? (
        <DropDownWithSearch
          onChange={props.onChange}
          id={props.id}
          value={
            (props.action === "view" || props.action === "edit") && props.ans
          }
          options={props.item}
          action={props.action}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid} error={props.error}
          label={props.item?.subOptionText || props.item?.questionHeader}
          required={props.designate && props.designate === "required"}
        />
      ) : props.field === "dropdown" &&
        props.item?.isMaster &&
        props.item?.isMaster === true &&
        props.item?.masterDataType !== "promoType" ? (
        <MasterDropDownEle
          onChange={props.onChange}
          id={props.id}
          ans={
            (props.action === "view" || props.action === "edit") && props.ans
          }
          options={props.item}
          action={props.action} error={props.error}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
        />
      ) : (
        <></>
      )}
      {props.field === "dropdown" &&
        props.item?.isMaster &&
        props.item?.isMaster === true &&
        props.item?.masterDataType === "promoType" && (
          <PromoTypeElement
            onChange={props.onChange}
            id={props.id}
            item={props.item}
            // label={props.label}
            ans={
              (props.action === "view" || props.action === "edit") && props.ans
            }
            // options={props.item}
            action={props.action}
            designate={props.designate}
            handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
            fieldData={props.fieldData}
            promoTypeId={props.promoTypeId || ""} error={props.error}
            handleChangeExits={props.handleChangeExits}
          />
        )}
      {props.field === "radio" && (
        <RadioEle
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          subOptions={props.subOptions}
          subOption1={props.subOption1}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
          SubOptionInput={props.SubOptionInput}
          SubOptionInputThree={props.SubOptionInputThree}
        />
      )}
      {props.field === "checkbox" && (
        <CheckEle
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
        />
      )}
      {props.field === "textarea" && (
        <TextEle
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
        />
      )}
      {props.field === "tabs" && (
        <TabsEle
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
        />
      )}
      {props.field === "multiSelect" && (
        <MultipleSelect
          label={props.label}
          options={props.options === undefined ? props.item : props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          designate={props.designate}
          handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
        />
      )}
    </>
  );
};

export default Element;
