import { useState } from "react";
import { Validator } from "utils/validations";
import { Select, Modal, Button, Row, Col, Input, Tag } from "antd";
import { FIELD_REQUIRED } from "utils/constants";
import { createPromoType, updatePromoTypeById } from "actions/promoTypes";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;

const PromoTypeForm = ({
  currentAction,
  modalOpen,
  currentRecord,
  onAdd,
  onUpdate,
  onClose,
}) => {
  const [promoTypeNameError, setPromoTypeNameError] = useState("");
  const [standardTeamError, setStandardTeamError] = useState("");
  const [emergencyTeamError, setEmergencyTeamError] = useState("");
  const [promoSubTypeError, setPromoSubTypeError] = useState("");
  const [standardWorkflow, setStandardWorkflow] = useState(
    currentRecord?.standardWorkflow?._id || ""
  );
  const [emergencyWorkflow, setEmergencyWorkflow] = useState(
    currentRecord?.emergencyWorkflow?._id || ""
  );
  const [subType, setPromoSubType] = useState(
    currentRecord?.subtype || ""
  );
  const [loading, setLoading] = useState(false);
  const [promoTypeName, setPromoTypeName] = useState(
    currentRecord?.promoTypeName || ""
  );
  const [status, setStatus] = useState(
    currentRecord?.statusValue !== undefined ? currentRecord?.statusValue : true
  );
  const dispatch = useDispatch();
  const { workflows } = useSelector((state) => state.workflow);
  const promoTypes = useSelector((state) => state.promoTypes.promoTypes);

  const handlePromoTypeName = (e) => {
    const { value } = e.target;
    const validationError = Validator.validate(
      "alphaNumeric_specialNotStart",
      value.trim(),
      1,
      null,
      true
    );
    const error =
      promoTypes.findIndex((item) => item.subType === value) !== -1
        ? "Promo Type already exists!"
        : validationError
          ? validationError
          : value.length > 30
            ? "Max 30 characters allowed"
            : "";
    setPromoTypeNameError(error);
    setPromoTypeName(value);
  };
  const handleStandardWorkflowChange = (e) => {
    setStandardWorkflow(e);
    setStandardTeamError("");
  };
  const handleEmergencyWorkflowChange = (e) => {
    setEmergencyWorkflow(e);
    setEmergencyTeamError("");
  };
  const handlePromoSubTypeChange = (e) => {
    setPromoSubType(e);
    setPromoSubTypeError("")
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    let newRecord = {};
    if (promoTypeNameError !== "" || promoTypeName === "") {
      setPromoTypeNameError(
        promoTypeName === "" ? FIELD_REQUIRED : promoTypeNameError
      );
      return;
    }
    if (!standardWorkflow) {
      setStandardTeamError(FIELD_REQUIRED);
      return;
    }
    if (!emergencyWorkflow) {
      setEmergencyTeamError(FIELD_REQUIRED);
      return;
    }
    if (!subType) {
      setPromoSubTypeError(FIELD_REQUIRED)
      return;
    }
    if (currentAction === "Create Promo Type") {
      setLoading(true);
      newRecord = {
        promoType: promoTypeName.trim(),
        standardWorkflow: standardWorkflow,
        emergencyWorkflow: emergencyWorkflow,
        subType: subType,
        status: false,
      };
      const response = await dispatch(createPromoType(newRecord));
      if (response.error === undefined) {
        handleCancel();
        window.scrollTo(0, 0);
        setLoading(false);
        onAdd();
      } else {
        handleCancel();
        setLoading(false);
        window.scrollTo(0, 0);
      }
    }

    if (currentAction === "Update Promo Type") {
      setLoading(true);
      newRecord = {
        promoType: promoTypeName.trim(),
        status: status,
        _id: currentRecord._id,
        standardWorkflow: standardWorkflow,
        emergencyWorkflow: emergencyWorkflow,
        subType: subType,
      };
      const response = await dispatch(updatePromoTypeById(newRecord));

      if (response.error === undefined) {
        handleCancel();
        window.scrollTo(0, 0);
        setLoading(false);
        onUpdate();
      } else {
        handleCancel();
        setLoading(false);
        window.scrollTo(0, 0);
      }
    }
  };
  const handleCancel = () => {
    onClose();
    setPromoTypeName("");
    setStandardWorkflow("");
    setEmergencyWorkflow("");
    setPromoSubType("");
    setStatus(true);
    setPromoTypeNameError("");
    setEmergencyWorkflow("");
    setStandardWorkflow("");
    setStandardTeamError("");
    setEmergencyTeamError("");
    setPromoSubType("");
  };
  return (
    <Modal
      open={modalOpen}
      title={currentAction}
      onOk={handleSubmit}
      onCancel={handleCancel}
      destroyOnClose
      footer={
        currentAction === "Create Promo Type" ||
          currentAction === "Update Promo Type"
          ? [
            <Button onClick={handleCancel}>Cancel</Button>,
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={loading}
              className="footerBtnNavy"
            >
              {loading
                ? "Loading..."
                : currentAction === "Create Promo Type"
                  ? "Add"
                  : "Save"}
            </Button>,
          ]
          : null
      }
    >
      {currentAction === "Create Promo Type" ||
        currentAction === "Update Promo Type" ? (
        <form onSubmit={handleSubmit}>
          <Row gutter={16} className="mb-25">
            <Col className="gutter-row" span={24}>
              <div>
                <label>
                  <span className="spanReq">*</span> Promo Type Name
                </label>

                <Input
                  placeholder="Write here"
                  value={promoTypeName}
                  className="mt-5"
                  onChange={handlePromoTypeName}
                  maxLength={31}
                />
                <span className="errorLabel">{promoTypeNameError}</span>
              </div>
            </Col>
          </Row>
          <Row gutter={16} className="mb-25">
            <Col className="gutter-row" span={24}>
              <div>
                <label>
                  <span className="spanReq">*</span> Standard Workflow
                </label>
              </div>

              <Select
                onChange={(value) => handleStandardWorkflowChange(value)}
                placeholder="Select"
                className="w-full"
                showSearch
                defaultValue={standardWorkflow}
              >
                {workflows
                  .filter((i) => i.type === "standard")
                  .map((item) => (
                    <Option key={item.name} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
              <span className="errorLabel">{standardTeamError}</span>
            </Col>
          </Row>
          <Row gutter={16} className="mb-25">
            <Col className="gutter-row" span={24}>
              <div>
                <label>
                  <span className="spanReq">*</span> Emergency Workflow
                </label>
              </div>

              <Select
                onChange={(value) => handleEmergencyWorkflowChange(value)}
                placeholder="Select"
                className="w-full"
                showSearch
                defaultValue={emergencyWorkflow}
              >
                {workflows
                  .filter((i) => i.type === "emergency")
                  .map((item) => (
                    <Option key={item.name} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
              <span className="errorLabel">{emergencyTeamError}</span>
            </Col>
          </Row>
          <Row gutter={16} className="mb-25">
            <Col className="gutter-row" span={24}>
              <div>
                <label>
                  <span className="spanReq">*</span> Promo Sub Type
                </label>
              </div>

              <Select
                onChange={(value) => handlePromoSubTypeChange(value)}
                placeholder="Select"
                className="w-full"
                showSearch
                defaultValue={subType}
              >
                <Option key="Simple" value='simple'>
                  Simple
                </Option>
                <Option key="Multi-buy" value='multi-buy'>
                  Multi-buy
                </Option>
              </Select>
              <span className="errorLabel">{promoSubTypeError}</span>
            </Col>
          </Row>
          {currentAction === "Update Promo Type" && (
            <Row gutter={16} className="mb-25">
              <Col className="gutter-row" span={24}>
                <div>
                  <label className="mr-10">Status</label>
                  <Tag color={status ? "green" : "red"}>
                    {status ? "Active" : "De-Active"}
                  </Tag>
                </div>
              </Col>
            </Row>
          )}
        </form>
      ) : (
        <></>
      )}
    </Modal>
  );
};
export default PromoTypeForm;
