import * as ActionTypes from "./action-types";
import qs from "qs";

export const SORT_ORDER = {
  ascend: "ASC",
  descend: "DSC",
};
const generatePromoURL = (params = {}) => {
  const {
    page = 1,
    pageSize = 10,
    sortBy,
    orderBy,
    search,
    filterName,
    actions,
  } = params;
  let url = `${process.env.REACT_APP_API_URL}/api/promo/v2?page=${page}&perPage=${pageSize}`;
  if (sortBy && orderBy) {
    url += `&sortBy=${sortBy}&orderBy=${SORT_ORDER[orderBy]}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (filterName && actions) {
    url += `&filterBy=${filterName}&filters[actions]=${actions}`;
  }
  return url;
};

export const getPromoList = (params = {}) => {
  if (params.orderBy) {
    params.orderBy = SORT_ORDER[params.orderBy];
  }
  let url = `${process.env.REACT_APP_API_URL}/api/promo/v2`;
  let queryParams = { ...params };

  if (params.filterBy === "status") {
    queryParams.filters = {...queryParams.filters, actions: "Total Promos" };
    delete queryParams.filterBy; // Remove filterBy from params to avoid conflicts
  }
  return {
    type: ActionTypes.GET_PROMO_LIST,
    payload: {
      request: {
        url,
        method: "get",
        params: queryParams,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    },
  };
};

export const getPromoByStatus = (
  name,
  value,
  page = 1,
  pageSize = 10,
  sortBy,
  orderBy
) => {
  const url = generatePromoURL({
    filterName: name,
    actions: value,
    sortBy,
    orderBy,
  });

  return {
    type: ActionTypes.PROMOBY_STATUS,
    payload: {
      request: {
        url: url,
        method: "get",
      },
    },
  };
};
export const getPromoDD = (search) => {
  const url = generatePromoURL({
    search,
  });

  return {
    type: ActionTypes.GET_PROMO_DD,
    payload: {
      request: {
        url: url,
        method: "get",
      },
    },
  };
};

export const getPromoListNames = () => ({
  type: ActionTypes.GET_PROMO_LIST_NAMES,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/names`,
      method: "get",
    },
  },
});

export const deletePromo = (id) => ({
  type: ActionTypes.DELETE_PROMO,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/${id}`,
      method: "delete",
    },
  },
});

export const viewPromo = (id) => ({
  type: ActionTypes.VIEW_PROMO,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/${id}`,
      method: "get",
    },
  },
});
export const viewPromoV2 = (id) => ({
  type: ActionTypes.VIEW_PROMO,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/v2/_id=${id}`,
      method: "get",
    },
  },
});

export const editPromo = (id, data) => ({
  type: ActionTypes.VIEW_PROMO,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/${id}`,
      data,
      method: "put",
    },
  },
});

export const changeStatusPromo = (id, data) => ({
  type: ActionTypes.CHANGE_STATUS_PROMO,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/${id}`,
      data,
      method: "patch",
    },
  },
});

export const createPromo = (data) => ({
  type: ActionTypes.CREATE_PROMO,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo`,
      data,
      method: "post",
    },
  },
});

export const sendSub1 = (value, id1, id2, label, masterDataType) => ({
  type: ActionTypes.SEND_SUB1,
  data: { value, id1, id2, label, masterDataType },
});

export const sendSubInput = (value, id1, id2, label) => ({
  type: ActionTypes.SEND_SUB_INPUT,
  data: { value, id1, id2, label },
});

export const sendSub2 = (value) => ({
  type: ActionTypes.SEND_SUB2,
  data: { value },
});

export const getPromoAssociatedBrand = (brand) => ({
  type: ActionTypes.GET_PROMO_ASSOCIATED_BRAND,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/associatedBrandId/${brand}`,
      method: "get",
    },
  },
});

export const getPromoAssociatedSku = (sku) => ({
  type: ActionTypes.GET_PROMO_ASSOCIATED_SKU,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/sku/promo/${sku}`,
      method: "get",
    },
  },
});

export const getPromoAssociatedShelf = (shelf) => ({
  type: ActionTypes.GET_PROMO_ASSOCIATED_SHELF,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/shelf/promos/${shelf}`,
      method: "get",
    },
  },
});

export const getPromoExportData = (params = {}) => {
  if (params.orderBy) {
    params.orderBy = SORT_ORDER[params.orderBy];
  }
  return {
    type: ActionTypes.GET_PROMO_EXPORT_DATA,
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/promo/v2?export=true`,
        method: "get",
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    },
  };
};

export const sendSubInputThree = (value, id1, id2, label) => ({
  type: ActionTypes.SEND_SUB_INPUT_THREE,
  data: { value, id1, id2, label },
});

export const getRequiredQuestionPromoType = (value) => ({
  type: ActionTypes.REQUIRED_QUES_PROMO_TYPE,
  data: { value },
});
export const getPromoStats = () => ({
  type: ActionTypes.GET_PROMO_STATS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/stats`,
      method: "get",
    },
  },
});

export const getPromoTypeForPromo = (event) => ({
  type: ActionTypes.GET_PROMOTYPE_FOR_PROMO,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/promoType?eventId=${event}`,
      method: "get",
    },
  },
});

export const getPromoTypeStage = (promoTypeId, event) => ({
  type: ActionTypes.CREATE_PROMO,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/promoType/${promoTypeId}?eventId=${event}`,
      method: "get",
    },
  },
});

export const updateStage = (id, data) => ({
  type: ActionTypes.UPDATE_STAGE,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promo/stages/${id}`,
      method: "patch",
      data: data,
    },
  },
});
export const getTimeLine = (id) => ({
  type: ActionTypes.GET_TIMELINE,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/timeline/${id}`,
      method: "get",
    },
  },
});
