export const triggerDownload = (data: any, fileName: string) => {
  const fileData = new Blob([data], { type: "text/csv" });
  const url = URL.createObjectURL(fileData);
  const downloadBtn: HTMLAnchorElement = document.createElement("a");
  downloadBtn.setAttribute("href", url);
  downloadBtn.setAttribute("download", fileName);
  document.body.appendChild(downloadBtn);
  downloadBtn.click();
  document.body.removeChild(downloadBtn);
  URL.revokeObjectURL(url);
};

export function replaceBracesWithSpace(xmlString:string) {
  return xmlString?.replace(/{{|}}/g, "");
}