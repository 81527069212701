/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// eslint-disable
import CommonLayout from "components/layout/Layout";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
} from "reactflow";
import { getAllTeams } from "actions/teams";
import { createWorkFlow, viewWorkflow, editWorkFlow } from "actions/workflow";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row, Select, Button, Alert } from "antd";
import { Validator } from "utils/validations";
import { useNavigate, useParams } from "react-router";
import "reactflow/dist/style.css";
import "./create.css";
import Team from "./Team";
import CustomEdge from "./Edge";
import {
  TeamOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { updateJsonViewNodes } from "./list";
const initialNodes = [];
const Option = Select;

let _id = 0;
let _edgeId = 0;
const getId = () => `dndnode_${_id++}`;
const getEdgeId = () => `edge_${_edgeId++}`;
const DnDFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { id } = useParams();

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const inst = useRef();
  const { teams } = useSelector((state) => state.teams);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [workflowType, setType] = useState("");
  const [typeError, setTypeError] = useState("");
  const [showNodeList, setShowNodeList] = useState(false);
  const [clickedNode, setClickedNode] = useState(null);
  const [clickedEdge, setClickedEdge] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [updateAlertText, setUpdateAlertText] = useState("");
  const [updateAlertType, setUpdateAlertType] = useState("");
  const [handleClicked, setHandleClicked] = useState();
  const ref = useRef(null);
  const pos = useRef(null);

  const navigate = useNavigate();

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const _drop_team_id = event.dataTransfer.getData("application/reactflow");
      const _drop_team_name = event.dataTransfer.getData(
        "application/reactflow_name"
      );

      // check if the dropped element is valid
      if (!_drop_team_id || !_drop_team_name) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        position,
        data: { label: _drop_team_name, teamId: _drop_team_id },
      };

      setNodes((nds) => nds.concat(newNode));
      if (nodes.length > 0) {
        const previousNode =
          nodes.filter((n) => !edges.some((e) => e.source === n.id))?.[0] || {};

        const edge = {
          id: getEdgeId(),
          source: previousNode.id,
          target: newNode.id,
        };
        setEdges((els) => [...els, edge]);
      }
    },
    [reactFlowInstance, nodes]
  );
  useEffect(() => {
    dispatch(getAllTeams({ perPage: 200 }));
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      getWorkFlowData(id);
    }
  }, [id]);
  const getWorkFlowData = async (id) => {
    const res = await dispatch(viewWorkflow(id));
    const data = res.payload?.data?.data || {};
    if (data.name) {
      setName(data.name);
    }
    if (data.type) {
      setType(data.type);
    }
    if (data.jsonView) {
      _id =
        data.jsonView.nodes.reduce((max, currentItem) => {
          const numericPart = currentItem.id.split("_")[1];
          const numericValue = parseInt(numericPart, 10); // or +numericPart;
          return numericValue > max ? numericValue : max;
        }, -Infinity) + 1;
      _edgeId =
        data.jsonView.edges.reduce((max, currentItem) => {
          const numericPart = currentItem.id.split("_")[1];
          const numericValue = parseInt(numericPart, 10); // or +numericPart;
          return numericValue > max ? numericValue : max;
        }, -Infinity) + 1;
      const { x = 0, y = 0, zoom = 1 } = data.jsonView.viewport;
      setNodes(
        updateJsonViewNodes(data.jsonView.nodes || [], data.stages || [])
      );
      setEdges(data.jsonView.edges || []);
      inst.current.setViewport({ x, y, zoom });
    }
  };
  const handleCancel = () => {
    navigate("/workflow");
  };

  const getSortedNodes = () => {
    const flow = reactFlowInstance ? reactFlowInstance.toObject() : {};
    if (flow.edges?.length && flow.nodes?.length) {
      const { edges, nodes } = flow;
      const sortedNodes = [];
      const initialNode = nodes.find(
        (item) => !edges.some((e) => e.target === item.id)
      );
      sortedNodes.push(initialNode);
      let nextEdge = edges.find((e) => e.source === initialNode.id);
      const findNextNode = (n) => n.id === nextEdge.target;
      while (nextEdge) {
        const nextNode = nodes.find(findNextNode);
        sortedNodes.push(nextNode);
        nextEdge = edges.find((e) => e.source === nextNode?.id);
      }

      return sortedNodes;
    } else if (flow?.nodes?.length) {
      return [...flow.nodes];
    }
    return [];
  };

  const handleSubmit = async () => {
    const flow = reactFlowInstance ? reactFlowInstance.toObject() : {};
    const newRecord = {
      name: name,
      type: workflowType,
      jsonView: flow,
      stages: getSortedNodes().map((i, index) => ({
        stage: index,
        teamId: i.data.teamId,
      })),
    };
    if (!name || !workflowType || !flow.nodes.length) {
      if (!name) {
        setNameError("Name is required");
      }
      if (!workflowType) {
        setTypeError("Type is required");
      }
      if (!flow.nodes.length) {
        setShowAlert("Select atleast one Team");
      }
      return;
    }
    if (id) {
      const res = await dispatch(editWorkFlow({ ...newRecord, id }));
      if (res.error === undefined) {
        navigate("/workflow");
      } else {
        setShowUpdateAlert(true);
        setUpdateAlertText(res?.error?.response?.data?.message);
        setUpdateAlertType("error");
      }
    } else {
      const res = await dispatch(createWorkFlow(newRecord));
      if (res.error === undefined) {
        navigate("/workflow");
      } else {
        setShowUpdateAlert(true);
        setUpdateAlertText(res?.error?.response?.data?.message);
        setUpdateAlertType("error");
      }
    }
  };
  const handleNameChange = (e) => {
    const { value } = e.target;
    setNameError(Validator.validate("title", value, null, null, true));
    setName(value);
  };
  const handleWorkflowType = (value) => {
    let errormsg =
      value === "" || value.length === 0 || value === null
        ? "Please Select Workflow Type"
        : "";
    setTypeError(errormsg);
    setType(value);
  };
  const onNodeClick = (event, node) => {
    // Handle click on a node
    setShowNodeList(true);
    // setSelectedNode(node);
    setClickedNode(node);
  };
  const nodeType = useMemo(() => {
    return {
      ...ReactFlow.defaultNodeTypes,
      default: (props) => (
        <Team
          {...props}
          onRemove={(id) => onRemove(id)}
          addClick={(id, handleType, e) => {
            const pane = ref.current.getBoundingClientRect();
            const top = e.clientY - pane.y;
            const left = e.clientX - pane.x;
            pos.current = { left, top };
            setShowNodeList(true);
            setHandleClicked({ id: id, handleType: handleType });
          }}
        />
      ),
    };
  }, []);
  const edgeTypes = useMemo(() => {
    return {
      ...ReactFlow.defaultNodeTypes,
      default: (props) => (
        <CustomEdge
          {...props}
          addClick={(id, e) => {
            const pane = ref.current.getBoundingClientRect();
            const top = e.clientY - pane.y;
            const left = e.clientX - pane.x;
            pos.current = { left, top };
            setShowNodeList(true);
            setClickedEdge(id);
          }}
        />
      ),
    };
  }, []);
  const onRemove = (nodeId) => {
    // Add your logic for the "Add" button action
    const _nodes = inst.current.getNodes();
    let _edges = inst.current.getEdges();

    const connectedEdges = _edges.filter(
      (el) => el.source === nodeId || el.target === nodeId
    );
    if (connectedEdges.length === 2) {
      const edge = {
        id: getEdgeId(),
        source:
          nodeId === connectedEdges[0].source
            ? connectedEdges[0].target
            : connectedEdges[0].source,
        target:
          nodeId === connectedEdges[1].source
            ? connectedEdges[1].target
            : connectedEdges[1].source,
      };
      _edges = [..._edges, edge];
    }
    // Remove the identified edges
    const updatedEdges = _edges.filter((el) => !connectedEdges.includes(el));
    setEdges(updatedEdges);

    // Remove the node
    const updatedNodes = _nodes.filter((el) => el.id !== nodeId);
    setNodes(updatedNodes);
  };
  const handleUpdateAlertClose = () => {
    setShowUpdateAlert(false);
  };
  const onNodeListClick = (node) => {
    // Add a new node to the graph when clicking on the node list
    const newNode = {
      id: getId(),
      data: { label: node.name, teamId: node._id },
      position: {
        x: 50,
        y: 50,
      }, // Adjust the position as needed
    };
    if (handleClicked) {
      let previousNode = nodes.filter((node) => node.id === handleClicked.id);
      previousNode = previousNode[0];
      if (handleClicked.handleType === "source") {
        newNode.position.x = previousNode.position.x + 150;
        newNode.position.y = previousNode.position.y + 150;
        const edge = {
          id: getEdgeId(),
          source: previousNode.id,
          target: newNode.id,
        };
        setEdges((els) => [...els, edge]);
        setNodes((nds) => nds.concat(newNode));
      } else {
        newNode.position.x = previousNode.position.x - 150;
        newNode.position.y = previousNode.position.y - 150;
        const edge = {
          id: getEdgeId(),
          source: newNode.id,
          target: previousNode.id,
        };
        setEdges((els) => [...els, edge]);
        setNodes((nds) => nds.concat(newNode));
      }
      setHandleClicked(null);
    } else {
      let selectedEdge = edges.filter((e) => e.id === clickedEdge);
      selectedEdge = selectedEdge[0] ? selectedEdge[0] : null;
      setNodes((nds) => nds.concat(newNode));
      if (nodes.length > 0 && selectedEdge) {
        const srcNode = nodes.filter((n) => n.id === selectedEdge.source)?.[0];
        const tNode = nodes.filter((n) => n.id === selectedEdge.target)?.[0];
        newNode.position.x = (srcNode.position.x + tNode.position.x) / 2;
        newNode.position.y = (srcNode.position.y + tNode.position.y) / 2;
        const edge1 = {
          id: getEdgeId(),
          source: selectedEdge.source,
          target: newNode.id,
        };
        const edge2 = {
          id: getEdgeId(),
          source: newNode.id,
          target: selectedEdge.target,
        };
        setEdges((els) =>
          els.filter((e) => e.id !== selectedEdge.id).concat(edge1, edge2)
        );
      }
    }

    setShowNodeList(false);
  };
  const filteredTeams = useMemo(() => {
    return teams
      .filter((team) => team.status === 1 && team.members > 0)
      .filter((team) => {
        return !nodes?.some((node) => {
          return node.data.teamId === team._id;
        });
      });
  }, [nodes, teams]);
  return (
    <CommonLayout
      className="no-content-mr workflow-create-wrapper"
      breadCrumbText={id ? "Update Workflow" : "Create Workflow"}
    >
      <div className="form">
        <form onSubmit={handleSubmit}>
          <Row gutter={16} className="workflow-create">
            <Col className="gutter-row" flex={"auto"}>
              <label className="create-workflow-label">
                Enter Workflow Name
              </label>
              <Input
                placeholder="Write here"
                value={name}
                className="mt-5"
                onChange={handleNameChange}
                maxLength={256}
              />
              <span className="error-label">{nameError}</span>
            </Col>
            <Col span={3}>
              <label className="create-workflow-label">Workflow Type</label>
              <Select
                placeholder="Select "
                onChange={handleWorkflowType}
                className="workflow-type"
                value={workflowType || undefined}
              >
                <Option value={"standard"}>Standard</Option>
                <Option value={"emergency"}>Emergency</Option>
              </Select>
              <span className="error-label">{typeError}</span>
            </Col>
            <Col>
              <Button
                className="workflow-create-button btn-secondary"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="workflow-create-button btn-primary"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </form>
      </div>
      <div className="dndflow">
        <DraggableNode
          nodes={teams
            .filter((team) => team.status === 1 && team.members > 0)
            .map((i) => ({ name: i.name, teamId: i._id }))}
          droppedElements={nodes}
        />
        <ReactFlowProvider>
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
            }}
            onClick={() => setShowNodeList(false)} // Close the node list when clicking on the graph area
          >
            <div className="reactflow-wrapper">
              <ReactFlow
                ref={ref}
                nodes={nodes}
                edges={edges}
                onNodesChange={() => onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={(instance) => {
                  setReactFlowInstance(instance);
                  inst.current = instance;
                }}
                onDrop={onDrop}
                onDragOver={onDragOver}
                fitView
                nodeTypes={nodeType}
                edgeTypes={edgeTypes}
                maxZoom={1}
              >
                <Controls />
              </ReactFlow>
            </div>
            {showNodeList && (clickedNode || clickedEdge || handleClicked) && (
              <div
                style={{
                  position: "absolute",
                  top:
                    pos.current.top + 100 > ref?.current?.offsetHeight
                      ? `50px`
                      : `${pos.current.top || 0}px`, // Adjust the position as needed
                  left: `${pos.current.left || 0}px`, // Adjust the position as needed
                  width: "200px",
                  border: "1px solid #F6F6F6",
                  zIndex: "10",
                  background: "white",
                  boxShadow: "10px 19px 20px rgba(0, 0, 0, 10%)",
                  bottom:
                    pos.current.top + 100 > ref?.current?.offsetHeight
                      ? `${ref.current.offsetHeight - pos.current.top}px`
                      : "50px",
                  overflow: "auto",
                }}
              >
                {filteredTeams.map((team) => (
                  <div
                    key={team.name}
                    onClick={() => onNodeListClick(team)}
                    className="list-team-item"
                  >
                    {team.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </ReactFlowProvider>
        {showAlert && (
          <Alert
            message={showAlert}
            type="error"
            showIcon
            closable
            onClose={() => setShowAlert(false)}
            icon={<DeleteOutlined />}
            className="alertStyle"
          />
        )}
      </div>
      {showUpdateAlert && (
        <Alert
          message={updateAlertText}
          type={updateAlertType}
          showIcon
          closable
          onClose={handleUpdateAlertClose}
          className="alertStyle"
        />
      )}
    </CommonLayout>
  );
};

export default DnDFlow;

const DraggableNode = ({ nodes, droppedElements }) => {
  const onDragStart = (event, node) => {
    event.dataTransfer.setData("application/reactflow", node.teamId);
    event.dataTransfer.setData("application/reactflow_name", node.name);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside>
      <div className="teams-text">
        Teams
        <Tooltip title="Only Active Teams & Teams with atleast 1 member are shown">
          <InfoCircleOutlined style={{ marginLeft: "6px", fontSize: "14px" }} />
        </Tooltip>
      </div>
      {nodes.map((node) => {
        return (
          <div
            key={node._id}
            className={
              droppedElements.find((i) => i.data.teamId === node.teamId)
                ? "dndnode input disabled"
                : "dndnode input"
            }
            onDragStart={(event) => onDragStart(event, node)}
            draggable={
              !droppedElements.find((i) => i.data.teamId === node.teamId)
            }
          >
            <div>
              <div className="team-node">
                <div className="team-icon-wrapper">
                  <TeamOutlined
                    style={{ fontSize: "14px", color: "#0F2A6F" }}
                  />
                </div>
                <div className="team-node-label">{node.name}</div>
              </div>
            </div>
          </div>
        );
      })}
    </aside>
  );
};
