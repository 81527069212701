import "./PromoTypes.css";
import { useEffect, useState } from "react";
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  FullscreenOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Alert, Button, Input, Modal, Spin, Switch, Tag } from "antd";
import Fullscreen from "fullscreen-react";
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "components/layout/Layout";
import CustomTable from "components/common/table/Table";
import TableSearchDropdown from "components/TableSearchDropdown";
// import { PROMO_TYPE_LIST_COLUMNS } from "utils/column";
import { TextMessage, SECONDS } from "utils/constants";
import { setPage } from "actions/app";
import {
  deletePromoTypeById,
  getAllPromoTypes,
  updatePromoTypeBySearch,
  updatePromoTypeStatus,
} from "actions/promoTypes";
import { useNavigate } from "react-router";
import { getWorkflowList, copyWorkFlow } from "actions/workflow/index";
import PromoTypeForm from "./PromoTypeForm";
import session from "utils/session";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
const { Search } = Input;


const PromoTypes = () => {
  const [isEnter, setIsEnter] = useState(false);
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const promoTypes = useSelector((state) => state.promoTypes.promoTypes);
  const searchedPromoTypes = useSelector(
    (state) => state.promoTypes.searchedPromoTypes
  );
  const teamDetail = session && session.teamDetail ? session.teamDetail : null;
  const role = session && session.role ? session.role.toLowerCase() : "";
  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [currentRecord, setCurrentRecord] = useState({});

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [showAddAlert, setShowAddAlert] = useState(false);
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [promoDeActive, setPromoDeActive] = useState(false);
  const [promoActive, setPromoActive] = useState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [reloadingState, setReLoadingState] = useState(false);

  const PROMO_TYPE_LIST_COLUMNS = [
    {
      title: "Name",
      isSortable: true,
      dataIndex: "promoTypeName",
      key: "promoTypeName",
      width: "30%",
      sorter: (a, b) =>
        a.promoTypeName.toString().localeCompare(b.promoTypeName.toString()),
    },
    {
      title: "Standard Workflow",
      dataIndex: "standardWorkflowName",
      key: "standardWorkflowName",
      width: "20%",
    },
    {
      title: "Emergency Workflow",
      dataIndex: "emergencyWorkflowName",
      key: "emergencyWorkflowName",
      width: "20%",
    },
    {
      title: "Activate/Deactivate",
      isSortable: true,
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <div>
          <div
            style={{
              padding: "4px 15px",
              background: "white",
              cursor: "pointer",
            }}
            onClick={() => handleCopy(record._id)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 1H4.625C4.55625 1 4.5 1.05625 4.5 1.125V2C4.5 2.06875 4.55625 2.125 4.625 2.125H12.375V12.875C12.375 12.9438 12.4312 13 12.5 13H13.375C13.4438 13 13.5 12.9438 13.5 12.875V1.5C13.5 1.22344 13.2766 1 13 1ZM11 3H3C2.72344 3 2.5 3.22344 2.5 3.5V11.7922C2.5 11.925 2.55312 12.0516 2.64687 12.1453L5.35469 14.8531C5.38906 14.8875 5.42813 14.9156 5.47031 14.9391V14.9688H5.53594C5.59062 14.9891 5.64844 15 5.70781 15H11C11.2766 15 11.5 14.7766 11.5 14.5V3.5C11.5 3.22344 11.2766 3 11 3ZM5.96875 14H5.96562L3.625 11.6594V11.6562H5.96875V14Z"
                fill="#434343"
              />
            </svg>
          </div>
        </div>
      ),
    },
  ];

  const handleCopy = async (record) => {
    const res = await dispatch(copyWorkFlow(record));
    setShowCopyAlert(true);
    navigate(`/promo-types/form-builder/${res?.payload?.data?.data?._id}`);

  };
  useEffect(() => {
    dispatch(setPage("Promo Types"));
    dispatch(getWorkflowList({ perPage: 100 }));
  }, [dispatch]);

  useEffect(() => {
    if (searchedText === "") {
      dispatch(updatePromoTypeBySearch([]));
    }
  }, [searchedText, dispatch]);

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getAllPromoTypes());
      if (res) {
        setLoadingState(false);
      }
    };
    getCall();
  }, [dispatch]);

  useEffect(() => {
    if (showAddAlert) {
      setTimeout(() => {
        setShowAddAlert(false);
      }, SECONDS);
    }
    if (showUpdateAlert) {
      setTimeout(() => {
        setShowUpdateAlert(false);
      }, SECONDS);
    }
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false);
      }, SECONDS);
    }
    if (showCopyAlert) {
      setTimeout(() => {
        setShowCopyAlert(false);
      }, SECONDS);
    }
  }, [showAddAlert, showUpdateAlert, showDeleteAlert, showCopyAlert]);

  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to deactivate this Promo Type?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => promoDeactivateYes(id, false),
      okCancel: promoDeactivateNo,
      okType: "primary",
    });
  };

  const promoDeactivateYes = async (id, checked) => {
    const resp = await dispatch(updatePromoTypeStatus(id, checked));
    if (resp.error) {
      showAlert({
        message: resp.error.response.data.message,
        variant: AlertVariant.ERROR
      });
    }
  };
  const promoDeactivateNo = () => {
    setPromoActive(true);
  };

  const changeStatusNo = async (id) => {
    Modal.confirm({
      title: "Do you want to activate this Promo Type?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => promoActivateYes(id, true),
      okCancel: promoActivateNo,
      okType: "primary",
    });
  };

  const promoActivateYes = async (id, checked) => {
    const resp = await dispatch(updatePromoTypeStatus(id, checked));
    if (resp.error) {
      showAlert({
        message: resp.error.response.data.message,
        variant: AlertVariant.ERROR
      });
    }
  };
  const promoActivateNo = () => {
    setPromoDeActive(false);
  };

  const setupRows = (promoTypes) => {
    return promoTypes?.map((o) => {
      return {
        promoTypeName: o.promoType,
        _id: o._id,
        standardWorkflowName: o.standardWorkflow?.name,
        emergencyWorkflowName: o.emergencyWorkflow?.name,
        standardWorkflow: o.standardWorkflow,
        emergencyWorkflow: o.emergencyWorkflow,
        status: o.status ? (
          <Switch
            checked={promoActive}
            className="switchStyle"
            onChange={() => changeStatusYes(o._id)}
          />
        ) : (
          <Switch
            checked={promoDeActive}
            onChange={() => changeStatusNo(o._id)}
          />
        ),
        statusValue: o.status,
        statusType: (
          <Tag color={o.status ? "green" : "red"}>
            {o.status ? "Active" : "De-Active"}
          </Tag>
        ),
        isStatus: o.status,
        subtype: o.subtype,
      };
    });
  };

  const handleAdd = () => {
    setModalOpen(true);
    setCurrentAction("Create Promo Type");
  };

  const updatePromoType = (record) => {
    setModalOpen(true);
    setCurrentAction("Update Promo Type");
    setCurrentRecord(record);
  };

  const deletePromoType = (value) => {
    Modal.confirm({
      title: TextMessage.CONFIRM_DELETE_PROMOTYPE_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deletePromoTypeYes(value),
      okType: "primary",
    });
  };

  const deletePromoTypeYes = async (value) => {
    await dispatch(deletePromoTypeById(value._id));
    window.scrollTo(0, 0);
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleUpdateAlertClose = () => {
    setShowUpdateAlert(false);
    setCurrentAction("");
  };

  const handleAddAlertClose = () => {
    setShowAddAlert(false);
    setCurrentAction("");
    setShowCopyAlert(false);
  };

  const onSearch = async (value) => {
    const searchedPromoTypeData = promoTypes.filter((item) =>
      item.promoType.toLowerCase().trim().includes(value.toLowerCase().trim())
    );
    dispatch(updatePromoTypeBySearch(searchedPromoTypeData));
  };

  const handleReload = async () => {
    setReLoadingState(true);
    const res = await dispatch(getAllPromoTypes());
    if (res) {
      setReLoadingState(false);
    }
  };

  const setColumns = () => {
    let cols = [];

    PROMO_TYPE_LIST_COLUMNS.forEach((o) => {
      if (o.dataIndex === "promoTypeName") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <TableSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
          onFilter: (value, record) => {
            const rec = record[o.dataIndex]
              ? record[o.dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
              : "";
            return rec;
          },
        };
        cols.push({ ...o, ...colsOptions });
      } else {
        cols.push(o);
      }
    });
    return cols;
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };

  const handleSearchChange = (e) => {
    setSearchedText(e.target.value);
  };

  const viewPromoTypes = (record) => {
    navigate(`/promo-types/form-builder/${record._id}`);
  };

  return (
    <CommonLayout className="content-mr" breadCrumbText="Promo Types">
      {loadingState || reloadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <>
          <div className="promo-type-card">
            <div className="promo-right-content">
              <Search
                placeholder="Search"
                enterButton
                allowClear
                className="searchPromoTypeBtn"
                onSearch={onSearch}
                onChange={handleSearchChange}
              />
            </div>

            <div className="promo-left-content">
              <Button
                type="primary"
                icon={<FileAddOutlined />}
                className="addPromoTypeBtn"
                onClick={handleAdd}
                disabled= {teamDetail?.isVCM}
              >
                Add Promo Type
              </Button>
            </div>
          </div>

          <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
            <div className="promo-table-card">
              <div className="promo-table-topbar">
                <div className="promoRightFilterGrpWrapper">
                  <div className="promoRightSettingGrp">
                    <ReloadOutlined
                      onClick={handleReload}
                      className="reloadIconStyle"
                    />
                    <ColumnHeightOutlined
                      className="reloadIconStyle"
                      onClick={handleTableHeight}
                    />
                    <FullscreenOutlined
                      onClick={() => {
                        setIsEnter(true);
                      }}
                      className="reloadIconStyle"
                    />
                  </div>
                </div>
              </div>

              {/* table */}

              <div className="w-100">
                <CustomTable
                  columns={setColumns()}
                  rows={setupRows(
                    searchedPromoTypes.length > 0
                      ? searchedPromoTypes
                      : promoTypes
                  )}
                  isEditable={!teamDetail?.isVCM}
                  isDeleteable={!teamDetail?.isVCM}
                  rowKey="_id"
                  onEdit={updatePromoType}
                  onDelete={deletePromoType}
                  total={promoTypes?.length}
                  size={tableSize}
                  isViewable={!teamDetail?.isVCM}
                  onView={viewPromoTypes}
                  isPopup={!teamDetail?.isVCM}
                  isPromoView={!teamDetail?.isVCM}
                />
              </div>

              {modalOpen ? (
                <PromoTypeForm
                  currentAction={currentAction}
                  modalOpen={modalOpen}
                  currentRecord={currentRecord}
                  onAdd={() => {
                    setShowAddAlert(true);
                    handleReload();
                  }}
                  onUpdate={() => {
                    setShowUpdateAlert(true);
                    handleReload();
                  }}
                  onClose={() => {
                    setModalOpen(false);
                    setCurrentAction("");
                    setCurrentRecord({});
                  }}
                />
              ) : (
                <></>
              )}

              {showDeleteAlert && (
                <Alert
                  message={TextMessage.PROMOTYPE_DELETE_TEXT}
                  type="success"
                  showIcon
                  closable
                  onClose={handleDeleteAlertClose}
                  icon={<DeleteOutlined />}
                  className="alertStyle"
                />
              )}

              {showUpdateAlert && (
                <Alert
                  message={TextMessage.PROMOTYPE_UPDATE_TEXT}
                  type={"success"}
                  showIcon
                  closable
                  onClose={handleUpdateAlertClose}
                  className="alertStyle"
                />
              )}
              {showAddAlert && (
                <Alert
                  message={TextMessage.PROMOTYPE_CREATE_TEXT}
                  type={"success"}
                  showIcon
                  closable
                  onClose={handleAddAlertClose}
                  className="alertStyle"
                />
              )}
              {showCopyAlert && (
                <Alert
                  message={'Copy of promotype created successfully'}
                  type={"success"}
                  showIcon
                  closable
                  onClose={handleAddAlertClose}
                  className="alertStyle"
                />
              )}
            </div>
          </Fullscreen>
        </>
      )}
    </CommonLayout>
  );
};

export default PromoTypes;
