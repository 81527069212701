import CommonLayout from "components/layout/Layout";
import { useEffect, useMemo, useRef, useState } from "react";
import "./style.css";
import { Button, Checkbox, Input, Spin } from "antd";
import QueryBuilder from "./query-builder";
import Step3 from "./step3";
import useSWR from "swr";
import ReportService from "services/report-service/report-service";
import { useNavigate, useParams } from "react-router";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
const { Search } = Input;

const steps = ["Step 1", "Step 2", "Step 3"];

export const Step1 = ({
  onCheckboxClick,
  fields = [],
  selectedFields,
  isLoading = false,
}) => {
  const [filteredFields, setFilteredFields] = useState(fields);

  useEffect(() => {
    setFilteredFields(fields);
  }, [fields]);

  const handleCheckboxChange = (e, field) => {
    onCheckboxClick(e.target.checked, field);
  };

  const onSearch = (e) => {
    const searchValue = e.target.value;
    if (searchValue) {
      const filteredFields = fields.filter((i) =>
        i.label?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredFields(filteredFields);
    } else {
      setFilteredFields(fields);
    }
  };

  return (
    <div className="step1">
      <div className="fields-search">
        <Search
          placeholder="Search "
          enterButton
          // allowClear
          onChange={onSearch}
          className="userBtn "
        />
      </div>
      <div className="fields">
        {isLoading ? (
          <span>
            Loading Fields... <Spin />
          </span>
        ) : (
          filteredFields.map((field) => (
            <div key={field.label} className="report-field-item">
              <Checkbox
                onChange={(e) => handleCheckboxChange(e, field)}
                checked={selectedFields?.find((i) => i.name === field.name)}
              >
                {field.label}
              </Checkbox>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const ReportCreate = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedFields, setSelectedFields] = useState([]);
  const [query, setQuery] = useState();
  const queryBuilderRef = useRef();
  const step3ref = useRef();
  const reportService = useMemo(() => new ReportService(), []);
  const { id } = useParams();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const { data, isLoading, isValidating } = useSWR(
    { key: "report-field" },
    () => reportService.getReportFields()
  );
  const fields = data?.data?.fields ?? [];

  const { data: reportData } = useSWR(
    { key: "report-details", id },
    (payload) =>
      payload.id ? reportService.getReportById(payload.id) : undefined
  );

  useEffect(() => {
    if (reportData) {
      const reportObj = reportData?.data?.report ?? {};
      setSelectedFields(reportObj.uiFilters ?? []);
      setQuery(reportObj?.rules);
    }
  }, [reportData]);

  const onCheckboxClick = (checked, field) => {
    if (checked) {
      setSelectedFields([...selectedFields, field]);
    } else {
      setSelectedFields(
        selectedFields.filter((item) => field.name !== item.name)
      );
    }
  };

  const handleCloseClick = () => {
    navigate("/reports", { replace: true });
  };

  const handleProcessClick = () => {
    if (currentStep === 1) {
      if (!queryBuilderRef.current?.isValid()) {
        return;
      }
      const localQuery = queryBuilderRef.current.getQuery();
      setQuery(localQuery);
    } else if (currentStep === 2) {
      handleSubmit();
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const isFormFilledOrValid = (step) => {
    if (step === 1 || step === 2) {
      return !!query;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (step3ref.current?.isValid()) {
      const data = step3ref.current.getData();
      if (data) {
        let response;
        if (id) {
          response = await reportService.updateReportById(id, data);
        } else {
          response = await reportService.createReport(data);
        }
        if (response) {
          showAlert({
            message: `Report ${id ? "updated" : "created"} successfully`,
            variant: AlertVariant.SUCCESS,
          });
          handleCloseClick();
        }
      }
    }
  };

  const handleStepClick = (step) => {
    if (isFormFilledOrValid(step)) {
      setCurrentStep(step);
    }
  };

  return (
    <CommonLayout className="no-content-mr" breadCrumbText="Reports">
      <div className="table-cardbody create-wrapper">
        <div className="report-create">
          <div className="flex teams-wrapper">
            {steps?.map((step, index) => (
              <button
                key={step}
                onClick={() => handleStepClick(index)}
                disabled={!isFormFilledOrValid(index)}
                className={`team-item ${index === currentStep ? "active" : ""}`}
              >
                <div className="team-content">
                  {step}
                  <div className="active-border"></div>
                </div>
              </button>
            ))}
          </div>
          <div className="create-report-wrapper">
            {currentStep === 0 && (
              <Step1
                onCheckboxClick={onCheckboxClick}
                fields={fields}
                isLoading={isLoading || isValidating}
                selectedFields={selectedFields}
              />
            )}
            {currentStep === 1 && (
              <QueryBuilder
                ref={queryBuilderRef}
                fields={selectedFields}
                initialquery={query}
              />
            )}
            {currentStep === 2 && (
              <Step3
                query={query}
                allFields={fields}
                selectedFields={selectedFields}
                ref={step3ref}
                prevReportDetails={reportData?.data?.report}
              />
            )}
          </div>
        </div>
      </div>
      <div className="report-footer">
        <Button onClick={handleCloseClick}>{"Cancel"}</Button>
        <Button
          type="primary"
          onClick={handleProcessClick}
          disabled={!selectedFields.length}
          className="next-btn"
        >
          {"Proceed"}
        </Button>
      </div>
    </CommonLayout>
  );
};
export default ReportCreate;
