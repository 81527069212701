export const columns = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Age", dataIndex: "age", key: "age" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    filters: [
      {
        text: "submitted",
        value: "submitted",
      },
      {
        text: "InComplete",
        value: "InComplete",
      },
      {
        text: "Completed",
        value: "Completed",
      },
    ],
    onFilter: (value, record) => {
      return record.status === value;
    },
  },
  { title: "Address", dataIndex: "address", key: "address" },
];

export const ERROR_TICKET_LIST_COLUMNS = [
  {
    title: "Ticket No",
    isSortable: true,
    dataIndex: "ticketNumber",
    key: "ticketNumber",
    sorter: true,
  },
  {
    title: "Title",
    isSortable: true,
    dataIndex: "title",
    key: "title",
    sorter: true,
  },
  {
    title: "Promo Name",
    isSortable: true,
    dataIndex: "promoNameId",
    key: "promoName.name",
    sorter: true,
  },
  {
    title: "Raised By",
    isSortable: true,
    dataIndex: "raisedBy",
    key: "raisedBy",
    sorter: true,
  },
  {
    title: "Status",
    isSortable: true,
    dataIndex: "status",
    key: "status",
    filters: [
      {
        text: "Open",
        value: "Open",
      },
      {
        text: "Resolved",
        value: "Resolved",
      },
    ],
  },
  {
    title: "Date",
    isSortable: true,
    dataIndex: "date",
    key: "date",
    sorter: true,
  },
  {
    title: "Priority",
    isSortable: true,
    dataIndex: "priority",
    key: "priority",
    filters: [
      {
        text: "High",
        value: "High",
      },
      {
        text: "Medium",
        value: "Medium",
      },
      {
        text: "Low",
        value: "Low",
      },
    ],
  },
  {
    title: "Action Owner",
    isSortable: true,
    dataIndex: "actionOwnerName",
    key: "actionOwnerName",
    sorter: true,
  },
];
export const USER_LIST_COLUMNS = [
  {
    title: "User Name",
    isSortable: true,
    dataIndex: "userName",
    key: "userName",
    sorter: (a, b) => a.userName.localeCompare(b.userName),
  },
  {
    title: "Email",
    isSortable: true,
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "Role",
    isSortable: true,
    dataIndex: "role",
    key: "role",
    sorter: (a, b) => a.role.localeCompare(b.role),
  },
  {
    title: "Access Type",
    isSortable: true,
    dataIndex: "accessType",
    key: "accessType",
    sorter: (a, b) => a.accessType.localeCompare(b.accessType),
  },
  {
    title: "Activate/Deactivate",
    isSortable: true,
    dataIndex: "active",
    key: "active",
  },
];

export const USER_TEAM_LIST_COLUMNS = [
  {
    title: "Name",
    isSortable: true,
    dataIndex: "userName",
    key: "userName",
    sorter: (a, b) => a.userName.localeCompare(b.userName),
  },
  {
    title: "Email",
    isSortable: true,
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: "Role",
    isSortable: true,
    dataIndex: "role",
    key: "role",
    sorter: (a, b) => a.role.localeCompare(b.role),
  },
];

export const SKU_LIST_COLUMNS = [
  {
    title: "SKU",
    isSortable: true,
    dataIndex: "sku",
    key: "sku",
    width: "10%",
    sorter: (a, b) => a.sku.localeCompare(b.sku),
  },
  {
    title: "Product Id",
    isSortable: true,
    dataIndex: "productId",
    key: "productId",
    width: "10%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
  {
    title: "Brand",
    isSortable: true,
    dataIndex: "brand",
    key: "brand",
    width: "10%",
    sorter: (a, b) => a.brand.localeCompare(b.brand),
  },
  {
    title: "Buyer Description",
    isSortable: true,
    dataIndex: "buyerdesc",
    key: "buyerdesc",
    width: "15%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
  {
    title: "Class Description",
    isSortable: true,
    dataIndex: "classdesc",
    key: "classdesc",
    width: "20%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
  {
    title: " Sub Class Description",
    isSortable: true,
    dataIndex: "subclassdesc",
    key: "subclassdesc",
    width: "20%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
  {
    title: "SKU Manufacturer Description",
    isSortable: true,
    dataIndex: "skumanufacturerdesc",
    key: "skumanufacturerdesc",
    width: "30%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
];

export const SHELF_LIST_COLUMNS = [
  {
    title: "Shelf Path",
    isSortable: true,
    dataIndex: "shelfPath",
    key: "shelfPath",
    width: "25%",
    sorter: (a, b) =>
      a.shelfPath.toString().localeCompare(b.shelfPath.toString()),
  },
  {
    title: "SKU Counts",
    isSortable: true,
    dataIndex: "skuKeys",
    width: "60%",
    key: "skuKeys",
  },
];

export const BRAND_LIST_COLUMNS = [
  {
    title: "Brands",
    isSortable: true,
    dataIndex: "brands",
    key: "brands",
    width: "40%",
    sorter: (a, b) => a.brands.localeCompare(b.brands),
  },
  {
    title: "No.SKU's",
    isSortable: true,
    dataIndex: "skuno",
    key: "skuno",
    width: "30%",
  },
];

export const BRAND_SKU_LIST_COLUMNS = [
  {
    title: "SKU",
    isSortable: true,
    dataIndex: "sku",
    key: "sku",
    width: "35%%",
    sorter: (a, b) => a.sku.localeCompare(b.sku),
  },
  {
    title: "Products",
    isSortable: true,
    dataIndex: "product",
    key: "product",
    width: "35%",
    sorter: (a, b) => a.product.localeCompare(b.product),
  },
  {
    title: "Day ID",
    isSortable: true,
    dataIndex: "dayId",
    key: "dayId",
    width: "30%",
    sorter: (a, b) => a.dayId?.localeCompare(b.dayId),
  },
];

export const SHELF_SKU_LIST_COLUMNS = [
  {
    title: "SKU Id",
    isSortable: true,
    dataIndex: "sku",
    key: "sku",
    width: "10%",
    sorter: (a, b) => a.sku.localeCompare(b.sku),
  },
  {
    title: "Products",
    isSortable: true,
    dataIndex: "product",
    key: "product",
    width: "15%",
    sorter: (a, b) => a.product.localeCompare(b.product),
  },
  {
    title: "Brand",
    isSortable: true,
    dataIndex: "brand",
    key: "brand",
    width: "10%",
    sorter: (a, b) => a.brand.localeCompare(b.brand),
  },
  {
    title: "Buyer Description",
    isSortable: true,
    dataIndex: "buyerdesc",
    key: "buyerdesc",
    width: "15%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
  {
    title: "Class Description",
    isSortable: true,
    dataIndex: "classdesc",
    key: "classdesc",
    width: "15%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
  {
    title: " Sub Class Description",
    isSortable: true,
    dataIndex: "subclassdesc",
    key: "subclassdesc",
    width: "15%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
  {
    title: "SKU Manufacturer Description",
    isSortable: true,
    dataIndex: "skumanufacturerdesc",
    key: "skumanufacturerdesc",
    width: "30%",
    sorter: (a, b) => a.productId.localeCompare(b.productId),
  },
  {
    title: "Day ID",
    isSortable: true,
    dataIndex: "dayId",
    key: "dayId",
    width: "30%",
    sorter: (a, b) => a.dayId?.localeCompare(b.dayId),
  },
];

export const PROMO_COLUMNS = [
  {
    title: "Adset",
    isSortable: true,
    dataIndex: "promoTag",
    key: "adset.name",
    width: "10%",
    sorter: (a, b) => a.adSet?.name.localeCompare(b.adSet?.name),
  },
  {
    title: "Event ID",
    isSortable: false,
    dataIndex: "eventId",
    key: "event.eventId",
    width: "10%",
    sorter: true,
  },
  {
    title: "Event",
    isSortable: false,
    dataIndex: "eventName",
    key: "event.name",
    width: "10%",
    sorter: true,
    // sorter: (a, b) => a.promoTag.localeCompare(b.promoTag),
  },
  {
    title: "Promo ID",
    isSortable: true,
    dataIndex: "promoId",
    key: "promoId",
    width: "20%",
    sorter: true,
  },
  {
    title: "Offer Name",
    isSortable: true,
    dataIndex: "name",
    key: "name",
    width: "20%",
    sorter: true,
  },
  {
    title: "Submitted By",
    isSortable: true,
    dataIndex: "submittedby",
    key: "owner.fullName",
    width: "15%",
    sorter: true,
  },
  {
    title: "Current Pending Team",
    isSortable: false,
    dataIndex: "actionTeamName",
    key: "actionTeamName",
    width: "15%",
    sorter: true,
  },
  {
    title: "Status",
    isSortable: true,
    dataIndex: "status",
    key: "status",
    width: "10%",
    filters: [
      {
        text: "Pending",
        value: "Pending",
      },
      {
        text: "Incomplete",
        value: "Incomplete",
      },
      {
        text: "Live",
        value: "Live",
      },
      {
        text: "Expired",
        value: "Expired",
      },
      {
        text: "Approved",
        value: "Approved",
      },
      {
        text: "Rejected",
        value: "Rejected",
      },
      {
        text: "Pending-Errors",
        value: "Pending-Errors",
      },
      {
        text: "Approved-Errors",
        value: "Approved-Errors",
      },
      {
        text: "Live-Errors",
        value: "Live-Errors",
      },
      {
        text: "Incomplete-Errors",
        value: "Incomplete-Errors",
      },
      {
        text: "Submitted-Errors",
        value: "Submitted-Errors",
      },
      {
        text: "Rejected-Errors",
        value: "Rejected-Errors",
      },
    ],
  },
  {
    title: "Workflow",
    isSortable: false,
    dataIndex: "workFlowType",
    key: "isEmergency",
    width: "10%",
    sorter: true,
    filters: [{
      text: "Emergency",
      value: "1",
    },
    {
      text: "Standard",
      value: "0",
    }]
    // sorter: (a, b) => a.promoTag.localeCompare(b.promoTag),
  },
  {
    title: "Promo Start Date",
    isSortable: true,
    dataIndex: "startDate",
    key: "startDate",
    width: "15%",
    sorter: true,
  },
  {
    title: "Promo End date",
    isSortable: true,
    dataIndex: "endDate",
    key: "endDate",
    width: "15%",
    sorter: true,
  },
  {
    title: "Activate/Deactivate",
    isSortable: true,
    dataIndex: "active",
    key: "active",
    width: "10%",
    align: "center",
    filters: [
      {
        text: "Active",
        value: 1,
      },
      {
        text: "Deactive",
        value: 0,
      },
    ],
  },
];
export const LOAD_PROMOS_COLUMNS = [
  {
    title: "Promo Name",
    isSortable: true,
    dataIndex: "promoName",
    key: "promoName",
    width: "20%",
    sorter: true,
  },
  {
    title: "Promo Status",
    isSortable: true,
    dataIndex: "promoStatus",
    key: "promoStatus",
    width: "20%",
    sorter: true,
    filters: [
      {
        text: "Pending",
        value: "Pending",
      },
      {
        text: "Incomplete",
        value: "Incomplete",
      },
      {
        text: "Live",
        value: "Live",
      },
      {
        text: "Expired",
        value: "Expired",
      },
      {
        text: "Approved",
        value: "Approved",
      },
      {
        text: "Rejected",
        value: "Rejected",
      },
      {
        text: "Pending-Errors",
        value: "Pending-Errors",
      },
      {
        text: "Approved-Errors",
        value: "Approved-Errors",
      },
      {
        text: "Live-Errors",
        value: "Live-Errors",
      },
      {
        text: "Incomplete-Errors",
        value: "Incomplete-Errors",
      },
      {
        text: "Submitted-Errors",
        value: "Submitted-Errors",
      },
      {
        text: "Rejected-Errors",
        value: "Rejected-Errors",
      },
    ],
  },
  {
    title: "Promo Type",
    isSortable: true,
    dataIndex: "promoTypeName",
    key: "promoTypeName",
    width: "20%",
    sorter: true,
  },
  {
    title: "Template Name",
    isSortable: true,
    dataIndex: "templateName",
    key: "templateName",
    width: "20%",
    sorter: true,
  },
  {
    title: "Template Type",
    isSortable: true,
    dataIndex: "templateType",
    key: "templateType",
    width: "20%",
    sorter: true,
    filters: [
      {
        text: "XML",
        value: "XML",
      },
      {
        text: "CSV",
        value: "CSV",
      }
    ],
  },
  {
    title: "Status",
    isSortable: true,
    dataIndex: "status",
    key: "status",
    width: "15%",
    filters: [
      {
        text: "Failed",
        value: "Failed",
      },
      {
        text: "Pending",
        value: "Pending",
      },
      {
        text: "Successful",
        value: "Successful",
      }
    ],
  }
]
export const PROMOCODE_LIST_COLUMNS = [
  {
    title: "Promo Codes",
    isSortable: true,
    dataIndex: "promoname",
    key: "promoname",
    width: "20%",
    sorter: (a, b) =>
      a.promoname.toString().localeCompare(b.promoname.toString()),
  },
  {
    title: "Status",
    isSortable: true,
    dataIndex: "status",
    key: "status",
    width: "15%",
    filters: [
      {
        text: "Pending",
        value: "Pending",
      },
      {
        text: "Incomplete",
        value: "Incomplete",
      },
      {
        text: "Live",
        value: "Live",
      },
      {
        text: "Expired",
        value: "Expired",
      },
      {
        text: "Approved",
        value: "Approved",
      },
      {
        text: "Rejected",
        value: "Rejected",
      },
      {
        text: "Pending-Errors",
        value: "Pending-Errors",
      },
      {
        text: "Approved-Errors",
        value: "Approved-Errors",
      },
      {
        text: "Live-Errors",
        value: "Live-Errors",
      },
      {
        text: "Incomplete-Errors",
        value: "Incomplete-Errors",
      },
      {
        text: "Submitted-Errors",
        value: "Submitted-Errors",
      },
      {
        text: "Rejected-Errors",
        value: "Rejected-Errors",
      },
    ],
    onFilter: (value, record) => {
      return record.status?.props?.children === value;
    },
  },
  {
    title: "Expiring date",
    isSortable: true,
    dataIndex: "expiringdate",
    key: "expiringdate",
    width: "15%",
    sorter: (a, b) => {
      const date1 = a.expiringdate === "-" ? null : a.expiringdate;
      const date2 = b.expiringdate === "-" ? null : b.expiringdate;
      return new Date(date1) - new Date(date2);
    },
  },
];

export const PROMO_TYPE_LIST_COLUMNS = [
  {
    title: "Name",
    isSortable: true,
    dataIndex: "promoTypeName",
    key: "promoTypeName",
    width: "30%",
    sorter: (a, b) =>
      a.promoTypeName.toString().localeCompare(b.promoTypeName.toString()),
  },
  {
    title: "Standard Workflow",
    dataIndex: "standardWorkflowName",
    key: "standardWorkflowName",
    width: "20%",
  },
  {
    title: "Emergency Workflow",
    dataIndex: "emergencyWorkflowName",
    key: "emergencyWorkflowName",
    width: "20%",
  },
  {
    title: "Activate/Deactivate",
    isSortable: true,
    dataIndex: "status",
    key: "status",
  },
  {
    title: "",
    key: "action",
    render: (_, record) => (
      <div>
        <div
          style={{
            padding: "4px 15px",
            background: "white",
            cursor: "pointer",
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1H4.625C4.55625 1 4.5 1.05625 4.5 1.125V2C4.5 2.06875 4.55625 2.125 4.625 2.125H12.375V12.875C12.375 12.9438 12.4312 13 12.5 13H13.375C13.4438 13 13.5 12.9438 13.5 12.875V1.5C13.5 1.22344 13.2766 1 13 1ZM11 3H3C2.72344 3 2.5 3.22344 2.5 3.5V11.7922C2.5 11.925 2.55312 12.0516 2.64687 12.1453L5.35469 14.8531C5.38906 14.8875 5.42813 14.9156 5.47031 14.9391V14.9688H5.53594C5.59062 14.9891 5.64844 15 5.70781 15H11C11.2766 15 11.5 14.7766 11.5 14.5V3.5C11.5 3.22344 11.2766 3 11 3ZM5.96875 14H5.96562L3.625 11.6594V11.6562H5.96875V14Z"
              fill="#434343"
            />
          </svg>
        </div>
      </div>
    ),
  },
];
export const TEAMS_LIST_COLUMNS = [
  {
    title: "Name",
    isSortable: true,
    dataIndex: "teamName",
    key: "teamName",
    sorter: (a, b) =>
      a.teamName.toString().localeCompare(b.teamName.toString()),
  },
  {
    title: "Members",
    isSortable: true,
    dataIndex: "members",
    key: "members",
    sorter: (a, b) => a.members.toString().localeCompare(b.members.toString()),
  },
  {
    title: "Activate/Deactivate",
    isSortable: true,
    dataIndex: "status",
    key: "status",
  },
];

export const ERRORS_TYPE_LIST = [
  {
    title: "Change type",
    isSortable: true,
    dataIndex: "errorType",
    key: "errorType",
    sorter: (a, b) => a.name.toString().localeCompare(b.name.toString()),
  },
  {
    title: "Activate/Deactivate",
    isSortable: true,
    dataIndex: "status",
    key: "status",
  },
];

export const UNASSIGN_USER_LIST_COLUMNS = [
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Roles",
    dataIndex: "role",
    key: "role",
  },
];

export const USER_PERMISSIONS = [
  {
    label: "User",
    value: "user",
  },
  {
    label: "Team",
    value: "team",
  },
  {
    label: "Workflow",
    value: "workflow",
  },
  {
    label: "Edit Promo",
    value: "promo",
  },
  {
    label: "Report",
    value: "report",
  },
  {
    label: "Promo Type",
    value: "promoType",
  },
  {
    label: "Automation Templates",
    value: "automationTemplates",
  },
  {
    label: "Load Promos",
    value: "loadPromo",
  },
];

export const WORKFLOW_LIST_COLUMNS = [
  {
    title: "Name",
    isSortable: true,
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.toString().localeCompare(b.name.toString()),
  },
  {
    title: "Type",
    isSortable: true,
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => a.type.toString().localeCompare(b.type.toString()),
  },
  {
    title: "Promo Types Associated",
    isSortable: true,
    dataIndex: "promoTypeCount",
    key: "promoTypeCount",
    sorter: (a, b) =>
      a.promoTypeCount.toString().localeCompare(b.promoTypeCount.toString()),
  },
];

export const SHELF_CSV_COLUMNS = [
  { label: "Shelf Path", key: "shelfPath" },
  { label: "SKU", key: "skuKeys" },
];

export const MAP_TYPE_COLUMNS = [
  {
    title: "Type",
    isSortable: true,
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
];

export const MAP_LIST_COLUMNS = [
  {
    title: "MAP ID",
    isSortable: true,
    dataIndex: "_id",
    key: "_id",
    sorter: (a, b) => a._id.localeCompare(b._id),
  },
  {
    title: "MAP Type",
    isSortable: true,
    dataIndex: "mapType",
    key: "mapType",
    filters: [
      {
        text: "SKU",
        value: "sku",
      },
      {
        text: "Brand",
        value: "brand",
      },
    ],
    onFilter: (value, record) => {
      return record.isMapType.toLowerCase() === value;
    },
  },
  {
    title: "Status",
    isSortable: true,
    dataIndex: "status",
    key: "status",
    filters: [
      {
        text: "Pending",
        value: "pending",
      },
      {
        text: "Approved",
        value: "approved",
      },
      {
        text: "Rejected",
        value: "rejected",
      },
    ],
    onFilter: (value, record) => {
      return record.isStatus.toLowerCase() === value;
    },
  },
  {
    title: "Created Date",
    isSortable: true,
    dataIndex: "createdDate",
    key: "createdDate",
    sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
  },
];

export const MAP_STATUS_LIST_COLUMNS = [
  {
    title: "MAP ID",
    isSortable: true,
    dataIndex: "_id",
    key: "_id",
    sorter: (a, b) => a._id.localeCompare(b._id),
  },
  {
    title: "MAP Type",
    isSortable: true,
    dataIndex: "mapType",
    key: "mapType",
    filters: [
      {
        text: "SKU",
        value: "sku",
      },
      {
        text: "Brand",
        value: "brand",
      },
    ],
    onFilter: (value, record) => {
      return record.isMapType.toLowerCase() === value;
    },
  },
  {
    title: "Status",
    isSortable: true,
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Created Date",
    isSortable: true,
    dataIndex: "createdDate",
    key: "createdDate",
    sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
  },
];

export const REPORT_COLUMN = [
  {
    title: "Reports",
    isSortable: true,
    dataIndex: "title",
    key: "title",
    sorter: true,
  },
  {
    title: "Status",
    isSortable: true,
    dataIndex: "status",
    key: "status",
    width: "20%",
    filters: [
      {
        text: "Private",
        value: "Private",
      },
      {
        text: "Public",
        value: "Public",
      },
    ],

  },
  {
    title: "Activate/Deactivate",
    isSortable: true,
    dataIndex: "activate",
    key: "activate",
    filters: [
      {
        text: "Activate",
        value: 1,
      },
      {
        text: "Deactivate",
        value: 0,
      },
    ],

  },
  {
    title: "Actions",
    isSortable: false,
    isSearchable: false,
    dataIndex: "action",
    key: "action",
    width: "30%"
  },
];

export const TICKET_REPORT_LIST_COLUMNS = [
  {
    title: "Ticket No",
    isSortable: true,
    dataIndex: "ticketNumber",
    key: "ticketNumber",
    sorter: true,
  },
  {
    title: "Title",
    isSortable: true,
    dataIndex: "title",
    key: "title",
    sorter: true,
  },
  {
    title: "Adset",
    isSortable: true,
    dataIndex: "adSet",
    key: "adSet",
    sorter: true,
  },
  {
    title: "Promo ID",
    isSortable: true,
    dataIndex: "promoID",
    key: "promoID",
    sorter: true,
  },
  {
    title: "Event",
    isSortable: true,
    dataIndex: "eventName",
    key: "eventName",
    sorter: true,
  },
  {
    title: "Event ID",
    isSortable: true,
    dataIndex: "eventID",
    key: "eventID",
    sorter: true,
  },
  {
    title: "COMP ID",
    isSortable: true,
    dataIndex: "comp_id",
    key: "comp_id",
    sorter: true,
  },
  {
    title: "Change Type",
    isSortable: true,
    dataIndex: "errorTypeName",
    key: "errorTypeDetails.name",
    sorter: true,
  },
  {
    title: "Promo Name",
    isSortable: true,
    dataIndex: "promoNameId",
    key: "promoName",
    sorter: true,
  },
  {
    title: "Raised By",
    isSortable: true,
    dataIndex: "raisedBy",
    key: "raisedBy",
    sorter: true,
  },
  {
    title: "Action Team",
    isSortable: true,
    dataIndex: "actionOwnerName",
    key: "teamDetails.name",
    sorter: true,
  },
  {
    title: "Status",
    isSortable: true,
    dataIndex: "status",
    key: "status",
    filters: [
      {
        text: "Open",
        value: "Open",
      },
      {
        text: "Resolved",
        value: "Resolved",
      },
      {
        text: "Cancelled",
        value: "Cancelled",
      },
    ],
  },
  {
    title: "Date",
    isSortable: true,
    dataIndex: "date",
    key: "date",
    sorter: true,
  },
  {
    title: "Priority",
    isSortable: true,
    dataIndex: "priority",
    key: "priority",
    filters: [
      {
        text: "High",
        value: "High",
      },
      {
        text: "Medium",
        value: "Medium",
      },
      {
        text: "Low",
        value: "Low",
      },
    ],
  },
  {
    title: "Validation",
    isSortable: true,
    dataIndex: "validation",
    key: "validation",
    sorter: true,
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
  },
];
