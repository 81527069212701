import "./CreateAdset.css";
import "../promotion/elements/elements.css";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Drawer, Space, Button, Input, DatePicker, Alert, Modal } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { DATE_FILTER_FORMAT, SECONDS } from "utils/constants";
import moment from "moment";
import { createAdset, updateAdset, deleteAdsetById } from "actions/calendar";
import { Validator } from "utils/validations";
import AdSetService from "services/adset/adset.service";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const CreateAdset = (props) => {
  const dispatch = useDispatch();
  const [promoName, setPromoName] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [dateError, setDateError] = useState("");

  const [requiredErrorText] = useState("");
  const [requiredError] = useState(false);

  const adsetService = new AdSetService();
  const { showAlert } = useAlert();

  useEffect(() => {
    const event = props.eventData;
    if (event) {
      setPromoName(event.name);
      // setDate([
      //   moment(event.startDate).format("MM/DD/YYYY"),
      //   moment(event.endDate).format("MM/DD/YYYY"),
      // ]);
      setDate([
        convertUTCToLocal(event.startDate),
        convertUTCToLocal(event.endDate),
      ]);
      setDescription(event?.description);
    }
  }, [props.eventData]);

  const handleName = (e) => {
    if (e.target.value.length === 0 || e.target.value.trim()) {
      setPromoName(e.target.value);
    }
    !e.target.value ? setNameError("This field is required") : setNameError("");
    setNameError(
      Validator.validate(
        "alphaNumeric_specialNotStart",
        e.target.value,
        null,
        null,
        true
      )
    );
  };
  const handleDate = (value, dateString) => {
    setDate(dateString);
    !dateString ? setDateError("This field is required") : setDateError("");
  };
  const convertDateFormat = (dateStr) => {
    return moment(dateStr, 'MM/DD/YYYY').format('YYYY-MM-DD');
  }
  const convertUTCToLocal = (utcDateStr) => {
    if (utcDateStr) {
      const date = moment.utc(utcDateStr).local().format('MM/DD/YYYY');
      return date;
    } else {
      return null;
    }
  }
  const handleSubmit = async (e) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (e === "Submit") {
      let api = {
        name: promoName.trim(),
        startDate: convertDateFormat(date[0]),
        endDate: convertDateFormat(date[1]),
        description,
      };
      setLoading(true);
      const res = await adsetService.addAdSet(api, timezone);
      setLoading(false);
      if (res?.status) {
        showAlert({
          message: res?.message || "Created successfully",
          variant: AlertVariant.SUCCESS,
        });
        props.onClose('Create', res.data);
      }
    }
    if (e === "Update") {
      let api = {
        name: promoName.trim(),
        startDate: convertDateFormat(date[0]),
        endDate: convertDateFormat(date[1]),
        description,
      };
      setLoading(true);
      const res = await adsetService.updateAdSet(props.eventData?._id, api, timezone);
      setLoading(false);
      if (res?.status) {
        if (res.message === "Adset consists of events, hence cannot be modified") {
          showAlert({
              message: res?.message,  
              variant: AlertVariant.ERROR,  
          });
          props.onClose();
        } else {
          showAlert({
              message: res?.message || "Updated successfully",
              variant: AlertVariant.SUCCESS,  
          });
          props.onClose('Update', res.data);
      }  
      } else {
        props.onClose();
      }
    }

    if (e === "Delete") {
      setLoading(true);
      const res = await dispatch(deleteAdsetById(props.eventData?._id));
      setLoading(false);
      if (res) {
        props.onClose("Delete");
      }
    }
  };

  const disabledDate = (current) => {
    const today = moment().startOf("day");
    return current && (current < today || current.isSame(today, "day"));
  };

  const deleteAdset = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this adset?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => handleSubmit("Delete"),
      okType: "primary",
    });
  };

  return (
    <div>
      <Drawer
        title={`${props.eventData ? "Update" : "Create"} an adset`}
        width={640}
        height={1900}
        onClose={props.onClose}
        open={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={
          <>
            <div>
              {props.eventData && (
                <Button
                  onClick={deleteAdset}
                  danger
                  disabled={props?.eventData?.events?.length || props.editable}
                >
                  {" "}
                  {"Delete"}
                </Button>
              )}
              <Space className="create-style">
                <Button onClick={() => props.onClose()} disabled={loading}>
                  {" "}
                  {loading ? `Loading...` : "Cancel"}
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    handleSubmit(props.eventData ? "Update" : "Submit")
                  }
                  disabled={
                    loading || !(promoName && date[0] && date[1] && !nameError) || props.editable
                  }
                >
                  {loading
                    ? `Loading...`
                    : `${props.eventData ? "Update" : "Submit"}`}
                </Button>
              </Space>
            </div>
          </>
        }
      >
        {requiredError && (
          <Alert
            message={requiredErrorText}
            type={"error"}
            className="create-frame"
            style={{ marginLeft: "24px" }}
          />
        )}
        <div className="create-body">
          {/* <Error /> */}
          <div className="create-frame">
            <div className="create-promo-frame">
              <div>
                <div className="d-label">
                  <span className="spanReq">*</span>{" "}
                  <label className="input-label">
                    What is the name of the Adset?
                  </label>
                </div>
                <Input
                  className="input-text-field"
                  onChange={handleName}
                  placeholder="Enter here"
                  value={promoName} disabled={props.editable}
                />
                <span className="error-label">{nameError}</span>

                <TextArea
                  name=""
                  id=""
                  placeholder="Additional description"
                  value={description} disabled={props.editable}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></TextArea>
              </div>
              <div className="date-frame" style={{ marginTop: "24px" }}>
                <div className="d-label">
                  <label className="input-label">
                    <span className="spanReq">*</span> When does the adset start
                    and end?
                  </label>
                </div>
                <RangePicker
                  picker="date"
                  format={DATE_FILTER_FORMAT}
                  onChange={handleDate}
                  className="select-date"
                  allowClear={false} disabled={props.editable}
                  value={[
                    date[0] && moment(date[0]),
                    date[1] && moment(date[1]),
                  ]}
                  disabledDate={disabledDate}
                  placeholder={['Start Date (MM/DD/YYYY)', 'End Date (MM/DD/YYYY)']}
                />
                <span className="error-label">{dateError}</span>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateAdset;
